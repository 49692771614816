<template lang="pug">
.main-wrapper.agendamento-salvar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-2
					Button.p-button-secondary.btn-back(v-if="cliente || desativarCliente || tipo_atendimento == 'E'" icon='jam jam-chevrons-left' @click='handleBack()')
				.p-col-10.ta-right(v-if="tipo_atendimento != 'E'")
					h1.text-header.text-secondary MedClub /
						a(@click='handleBack()' :style="{cursor:'pointer'}") Agendamentos
						span /
						b Criar

		DialogObservacoes(:display='dialogObservacoes.display' :procedimentos='dialogObservacoes.procedimentos' @close='dialogObservacoes.display=false')
		DialogAgendaLivreLimitada(
			v-if= 'dialogAgendaLL'
			:display = 'dialogAgendaLL'
			:procedimento = 'procALL'
			:model = 'model'
			@agendar = 'reservarLaboratorialALL'
			@close = 'dialogAgendaLL = false'
		)
		DialogPacotes(
			:display = 'dialogPacotes'
			:model = 'selectedPacote'
			:cd_atendimento = 'idAtendimento != 0? idAtendimento : cliente?.atendimento?.id'
			:paciente = '$refs?.buscarPaciente?.model'
			@agendaPacotes = 'agendaPacotes'
			@close = 'dialogPacotes = false'
			:waitingCadastroAgenda = waitingCadastroAgenda
		)
		ConfirmDialog(
			:display='dialogApagarLaboratorial'
			positiveText='Permanecer na tela'
			negativeText='Trocar de aba'
			styleClass='dialogApagarLaboratorial'
			@close='dialogApagarLaboratorial = false'
			@reject='ignorarConfirmacaoLaboratorial = true;'
		)
			template(#text-content="props")
				span <b>Os exames laboratorias selecionados ainda não foram inseridos no carrinho.</b>
		ConfirmDialog(
			:display='dialogAutorizarEmpresa'
			@close='dialogAutorizarEmpresa = false'
			@reject='dialogAutorizarEmpresa = false;'
			@confirmed='autorizarEmpresa()'
		)
			template(#text-content="props")
				span <b>Deseja prosseguir com a autorização das guias?</b>
		Dialog(header='Pacote suspenso' :visible.sync='dialogPacoteBloqueado' :modal='true')
			.p-grid.ta-center(style="width: 20rem")
				.p-col-12
					p.modal-title(style="font-size: 1rem") O pacote selecionado está suspenso.
					Button.p-button(label='Ok' @click='dialogPacoteBloqueado = false' style="width: 30%")

		DialogObsImprimir(:display='dialogObsImprimir' :selecionados='selecionados' @close='dialogObsImprimir=false')

		Dialog(header='Remover todos os itens' :visible.sync='dialogApagar' :modal='true')
			.p-grid.ta-center(style="width: 20rem")
				.p-col-12
					b.modal-title(style="font-size: 1rem") Deseja mesmo remover todos?
				.p-col-12(v-if="waitingForm")
					ProgressBar(mode="indeterminate")
				div.p-col-12.p-grid(v-else)
					.p-col-6
						Button.p-button(label='Não' @click='dialogApagar = false' style="width: 100%")
					.p-col-6
						Button.p-button-danger(label='Remover' @click='removerTodos()' style="width: 100%")
		
		Dialog.dialogProcedimentos(:header='`${dialogProcedimentos_data?.ds_pacote} - R$ ${dialogProcedimentos_data?.valores?.length? dialogProcedimentos_data?.valores[0].nr_preco: ""}`' :visible.sync='dialogProcedimentos' :modal='true'
		@hide='dialogProcedimentos = false; dialogProcedimentos_data = {}' )
			.p-grid
				.p-col-6(v-for='agendamento in dialogProcedimentos_data?.cd_procedimentos' :key='agendamento.id')
					Card.card-agendamento
						template(slot="content")
							h1.text1(v-if='!agendamento.info.cd_procedimentos') {{ agendamento.info.ds_procedimento }}
							.waitingRemover(v-if='waitingRemover == agendamento.id')
								ProgressSpinner(strokeWidth='2')
							div(v-else)
								br(v-if='!agendamento.info.cd_procedimentos')
								//- p.text1 <b>Paciente: </b> {{ agendamento.info.nm_paciente }}
								p.text1 <b>Local: </b> {{ agendamento.info.nm_estabelecimento }}
								p.text1(v-if="agendamento.info.ie_tipo_agenda === 'C'") <b>Especialidade: </b> {{ agendamento.info?.nm_especialidade?.length ? agendamento.info.nm_especialidade : agendamento.info.ds_procedimento }}
								//- div(v-else style='margin-top: 3px')
								//- 	p.text1 <b>Procedimento: </b> {{ agendamento.info.ds_procedimento }}
								div(v-if="agendamento.info.nm_especialista_solicitante" style='margin-top: 3px')
									p.text1 <b>Solicitante: </b> {{ agendamento.info.nm_especialista_solicitante }}
								//- p.text1(v-if='agendamento.info.preco_f')
								//- 	b Preço:
								//- 		span.valor {{ agendamento.info.preco_f }}
								p.text1(v-if='agendamento.info.ie_agenda') <b>Especialista: </b> {{ agendamento.info.nm_especialista }} <br> <b>Data: </b> {{ agendamento.info.dt_agenda_f }} - {{ agendamento.info.hr_agenda_f }}
								p.text1(v-if='agendamento.info.cd_procedimentos') Lista de exames ( {{ agendamento.info.cd_procedimentos?.length }} )
									span(v-for='item, idx in agendamento.info.cd_procedimentos' :key='idx')
										br
										b {{ idx+1 }}. &nbsp;
										span {{ item.nm_procedimento }} &nbsp;
		

		Dialog.dialog-opcoes-impressao(header="Opções de impressão" :modal="true" :visible.sync="dialogOpcoesImpressao")
			.ta-center.my-2
				ProgressSpinner.waiting-imprimir(v-if='waitingImprimir' strokeWidth="6")
				div(v-else)
					Button.p-button-info.mr-2(label='Impressão térmica' icon='jam jam-ticket' @click='imprimirReservados(true)')
					Button.p-button-success(label='Impressão A4' icon='jam jam-printer' @click='imprimirReservados()')

		Dialog.dialog-reservado(header="Procedimento já reservado" :modal="true" :visible.sync="dialogReservados")
			.ta-center.my-2
				label.form-label(v) {{ model.ie_tipo_exame  === "L" ? 'Algum desses procedimentos já foi reservado' : 'Esse procedimento já foi reservado' }}. É possível alterar a quantidade de cada procedimento sem ter que fazer uma nova reserva.
				div.mt-3
					Button.p-button-success.mr-2(label='Adicionar mesmo assim' icon='jam jam-plus' @click='dialogReservados = false; handleSubmit(true)')
					Button.p-button-danger(label='Cancelar' icon='jam jam-undo' @click='dialogReservados = false')

		Dialog.dialogCriarHorario(header='Criar horário' :visible.sync='dialogCriarHorario' :modal='true')
			.ta-center.my-4
				label.form-label Horário de Atendimento:
				InputMask(v-model='dialogCriarHorario_data.hr_agenda' mask='99:99')
			.ta-center.my-4
				Button.p-button(label='Criar' @click='criarHorario()')

		Dialog.dialogAtendimentos(header='Atendimentos em aberto' :visible.sync='dialogAtendimentos' :modal='true')
			div(v-if='dialogAtendimentos_data.length')
				div
					p(style='margin:4px 0') <b>Cliente:</b> {{ dialogAtendimentos_data[0].cd_pessoa_fisica.nm_pessoa_fisica }}
					p(style='margin:4px 0' v-if='dialogAtendimentos_data[0].cd_pessoa_fisica.nr_cpf') <b>CPF:</b> {{ $root.formatCPF(dialogAtendimentos_data[0].cd_pessoa_fisica.nr_cpf, true) }}
				ProgressBar(v-if='atendimentoSelectedTable' mode="indeterminate")
				span(v-else)
					DataTable.my-4(:value='dialogAtendimentos_data' :selection.sync="dialogAtendimentos_selected"
						selectionMode="single" dataKey="id" @row-select='$router.push(`/agendamento/salvar/${$event.data.id}`); $router.go()')
						Column(headerStyle='width:20%' bodyStyle='text-align:center' header='Nº atendimento')
							template(#body='props')
								span {{ $root.padStart(props.data.id, 8) }}
						Column(headerStyle='width:20%' bodyStyle='text-align:center' header='Origem')
							template(#body='props')
								span(v-if="props.data.cd_origem_atendimento") {{ props.data.cd_origem_atendimento.ds_origem }}
								span(v-else) {{ props.data.ds_origem }}
						Column(headerStyle='width:15%' bodyStyle='text-align:center' header='Data' field='dt_realizado')
						Column(headerStyle='width:15%' bodyStyle='text-align:center' header='Qtd. itens' field='horarioagendamento_set.length')
						Column(headerStyle='width:15%' bodyStyle='text-align:center' header='Valor total' field='nr_valor_total_f')
						Column(headerStyle='width:15%' bodyStyle='text-align:center' header='Ações')
							template(#body='props')
								Button.p-button-rounded(icon='jam jam-write' @click='redireciona(props.data)' v-tooltip.top="'Editar'")
					.ta-center
						Button(label='Novo atendimento' style='font-size:12px' @click='dialogAtendimentos = false; selecionarCliente(true)')

		Dialog.dialogAtendimentos(header='Empresa Suspensa' :modal='true' :visible.sync='dialogEmpresaSuspensa')
			h4.ta-left Motivo:
			p(:style="{'overflow-wrap': 'break-word'}") {{ motivoSuspensaoEmpresa }}

		.__box-imprimir(v-show='agendamentos.length')
			.ta-center(style='width: 100%; margin: 0 auto;')
				img.img-logo(src='./../../assets/img/logo-medclub.png')
				h1.title ORÇAMENTO

			.p-grid(v-if="cliente")
				.p-col-8
					span <b>Cliente:</b> {{ cliente.nm_pessoa_fisica }}
				.p-col-4
					span <b>CPF:</b> {{ cliente.nr_cpf_f }}

			table
				tr
					td(width='5%') <b>Qtd</b>
					td(width='45%') <b>Procedimento / Especialidade</b>
					td(width='30%') <b>Estabelecimento</b>
					td(width='20%') <b>Preço</b>
				tr(v-for='agendamento, idx in agendamentos' :key='idx')
					td {{ agendamento.info.nr_quantidade }}
					td(v-if="agendamento.info.ie_tipo_agenda === 'C'")
						span {{ agendamento.info.nm_especialidade?.length ? agendamento.info.nm_especialidade : agendamento.info.ds_procedimento }}
					td(v-else)
						span {{ agendamento.info.ds_procedimento }}
					td {{ agendamento.info.nm_estabelecimento }}
					td {{ agendamento.info.preco_f }}
				tr
					td.ta-right(colspan=3) <b>Total: </b>
					td {{ agendamentos_sum() }}

			.ta-center(style='width: 100%; margin: 2.5rem auto 0 auto;')
				h1.title ESTABELECIMENTOS SELECIONADOS

			div(v-for='estabelecimento, idx in estabelecimentosAgendados' :key='idx' style='width: 100%; font-size: 15px; margin: 1.7rem auto;')
				span(style='display: block') <b>Nome:</b> {{ estabelecimento.nm_estabelecimento }}
				span(style='display: block') <b>Contato:</b> {{ estabelecimento.ds_contatos }}
				span(style='display: block') <b>Endereço:</b> {{ estabelecimento.nm_endereco }}

			p.ta-right(style="font-size: 10px; margin: 0;") <b>Impresso em {{ dataImpressao }}</b>

		form(@submit.prevent='handleSubmit()')
			Panel(:header="`Criar agendamento`")
				ProgressBar(v-if='waitingAtendimento' mode="indeterminate")
				form.p-grid.p-fluid.p-justify-center(v-else-if='! cliente && ! desativarCliente && !atendimentoSelected' @submit='$event.preventDefault()')
						.p-col-6
							BuscarPaciente(ref='buscarPaciente2' label='Cliente' :cancelarPopup='true' :medEmpresa='tipo_atendimento == "E"' :hideCadastro='tipo_atendimento == "E"' @empresaInativa='dialogEmpresaSuspensa = !!$event, motivoSuspensaoEmpresa = $event')
						.p-col-3
							OrigemDropdown(ref='dropdownOrigem' v-model='atendimentoOrigem')
						.p-col-2.ta-center(v-if='waitingNomeUsuario')
							ProgressSpinner.waitingNomeUsuario(strokeWidth='5')
						.p-col-2(v-else style='margin-top:21px')
							Button(
								label='Selecionar'
								type='button'
								icon='jam jam-check'
								@click='!!motivoSuspensaoEmpresa ? dialogEmpresaSuspensa = true : selecionarCliente()')

				.p-grid.p-fluid.p-justify-center(v-show='cliente || desativarCliente ||(!waitingAtendimento && atendimentoSelected)')
					.p-grid.p-justify-center.form-atendimento(ref='agendamentos')
						span(ref='cliente')
							.p-grid
								.p-col-12(v-if='cliente')
									.p-grid.p-fluid.p-align-end.p-justify-end
										.p-col-2
											label.form-label(v-tooltip.top="'Nº de Atendimento'") Nº Atend.:
											.p-inputgroup(v-tooltip.top="'Nº de Atendimento'")
												InputText.nomeUsuario2(readonly :value='`${ $root.padStart(cliente.atendimento.id, 8) }`')
												Button.copy-button(v-if='tipo_atendimento == "E"' icon='jam jam-files' type='button' @click="copy($root.padStart(cliente.atendimento.id, 8))")

										.p-col-8
											label.form-label Nome do cliente:
											.p-inputgroup
												InputText.nomeUsuario2(readonly :value='`${ titular.nm_pessoa_fisica }`')

										.p-col-2
											label.form-label(v-tooltip.top="'Créditos Medclub'") {{ tipo_atendimento == 'E' ? 'Saldo do Titular:' : 'Créditos:' }}
											.p-inputgroup(v-tooltip.top="'Créditos Medclub'")
												InputText.nomeUsuario2.credito-medclub(readonly :value='formatPrice(saldoTitular)')
										.p-col-12(v-if='this.tipo_atendimento == "E"')
											label.form-label Empresa Associada:
											.p-inputgroup
												InputText.nomeUsuario2(readonly :value='titular.empresa_associada_f.empresa.nm_fantasia')
								.p-col-12
									BuscarPaciente(ref='buscarPaciente' :hideSearchForm='cliente && !mostrarNoEmpresa' :ocultaLimpar='tipo_atendimento == "E"' :medEmpresa='tipo_atendimento == "E"')
								.p-col-12(v-show='mostrarNoEmpresa')
									span
										label.form-label Tipo de agendamento:
										SelectButton.ie_tipo_agenda(
											v-model='model.ie_tipo_agenda'
											:options='options.ie_tipo_agenda'
											optionLabel='text'
											optionValue='value'
											@input='model.ie_tipo_exame = null'
											@focus='confirmaApagarLaboratorial($event)'
										)
									span(v-if="isC")
										label.form-label Tipo de atendimento:
										SelectButton.ie_tipo_atendimento(
											v-model='model.ie_tipo_atendimento'
											:options='options.ie_tipo_atendimento'
											optionLabel='text'
											optionValue='value'
										)

										span(v-if="model.ie_tipo_atendimento == 1")
											label.form-label Agenda:
											SelectButton.ie_tipo_atendimento(
												v-model='model.ie_agenda'
												:options='options.ie_agenda'
												optionLabel='text'
												optionValue='value'
											)

									span(v-if="isEorP")
										span(v-if="model.ie_tipo_agenda === 'E'")
											label.form-label Tipo de exame:
											SelectButton.ie_tipo_atendimento(
												v-model='model.ie_tipo_exame'
												:options='options.ie_tipo_exame'
												optionLabel='text'
												optionValue='value'
												@input='model.cd_procedimento = null'
												@focus='confirmaApagarLaboratorial($event)'
											)
										label.form-label Médico solicitante:
										CustomDropdown.medico-solicitante-select(
											ref="medicoSolicitanteDropdown"
											v-model="model.cd_especialista_solicitante"
											optionLabel="text"
											optionValue="value"
											dataKey="value"
											placeholder="- Selecione - (Opcional)"
											:filter="true"
											:showClear="true"
											:options="options.medicosSolicitantes"
											:loadingOptions="medicoSolicitante.waiting"
											:loadingMore="medicoSolicitante.waitingNextPage"
											:filterFunction="getMedicoSolicitanteOptions"
											@end-scroll="onEndScrollMedicoSolicitante"
										)
										div(v-if="model.ie_tipo_exame || model.ie_tipo_agenda === 'P'")
											label.form-label Procedimento:
											CustomMultiSelect(
                                                v-if="model.ie_tipo_exame === 'L'"
												ref='dropdownProcedimentos'
                                                v-model='model.cd_procedimento'
                                                dataKey='value' optionLabel='text'
                                                optionValue='value' display='chip'
                                                placeholder='- Selecione -' filter
                                                :showSelected='true'
                                                :options='options.procedimentos'
                                                :loadingOptions="waitingProcedimentos"
                                                :loadingMore="waitingNextPageProcedimentos"
                                                :filterFunction="getProcedimentos"
                                                @end-scroll="onEndScrollProcedimentos"
                                            )
												//- template(#label='props')
												//- 	span {{ findProcedimentoById(props.value) }}
												template(#option='props')
													span <b>{{ props.option.textAlt }}</b>
													span(v-if="props.option.nm_subgrupo")
														b.credito-medclub &nbsp; | SUBGRUPO: &nbsp;
														b {{ props.option.nm_subgrupo }}
													br
													span
														span(v-if="props.option.textAlt != '- Selecione -' && props.option.ds_sinonimia") {{ props.option.ds_sigla }} - {{ props.option.nm_procedimento }} ({{ props.option.ds_sinonimia }})
														span(v-else-if="props.option.textAlt != '- Selecione -'") {{ props.option.ds_sigla }} - {{ props.option.nm_procedimento }}
														br
														span(v-if="props.option.nr_menor_preco") - A partir de &nbsp;
															b.credito-medclub.text-caption {{formatPrice(props.option.nr_menor_preco)}}
											CustomDropdown(v-else
												ref='dropdownProcedimentos'
												v-model="model.cd_procedimento"
												optionLabel="text"
												optionValue="value"
												dataKey="id"
												placeholder="- Selecione -"
												:filter="true"
												:options="options.procedimentos"
												:loadingOptions="waitingProcedimentos"
												:loadingMore="waitingNextPageProcedimentos"
												:filterFunction="getProcedimentos"
												@end-scroll="onEndScrollProcedimentos"
											)
												template(#option='props')
													span <b>{{ props.option.textAlt }}</b>
													span(v-if="props.option.nm_subgrupo")
														b.credito-medclub &nbsp; | SUBGRUPO: &nbsp;
														b {{ props.option.nm_subgrupo }}
													br
													span(v-if="props.option.textAlt != '- Selecione -' && props.option.ds_sinonimia") {{ props.option.ds_sigla }} - {{ props.option.nm_procedimento }} ({{ props.option.ds_sinonimia }})
													span(v-else-if="props.option.textAlt != '- Selecione -'") {{ props.option.ds_sigla }} - {{ props.option.nm_procedimento }}
									span(v-if='isPC')
										label.form-label Tipo de pacote:
										SelectButton.ie_tipo_atendimento(
											v-model='model.ie_tipo_pacote'
											:options='options.ie_tipo_pacote'
											optionLabel='text'
											optionValue='value'
											@input="getPacotes()"
										)
										span(v-if='model.ie_tipo_pacote')
											label.form-label Pesquisa de pacote:
											CustomDropdown(
												v-model="model.nm_busca_pacote"
												optionLabel="text"
												optionValue="value"
												dataKey="value"
												placeholder="- Selecione -"
												:filter="true"
												:options="options.tiposPacotes"
												:loadingOptions="waitingPacotes"
												:loadingMore="waitingNextPagePacotes"
												:filterFunction="getPacotes"
												@end-scroll="onEndScrollPacotes"
												@input="buscarPacote()"
											)
									
										span(v-if="model.nm_busca_pacote")
											label.form-label Pacotes:
											ProgressBar(v-if='waitingPacotes' mode="indeterminate")
											p.ta-center(v-else-if='!options.pacotes.length' style='font-size: 12px;') <b>Sem Pacotes correspondentes.</b>

											DataTable.carrinho-datatable(v-else @row-select='model.cd_pacote = $event.data.id'
												:value='options.pacotes' dataKey='id' :selection.sync="selectedPacote")
												Column(headerStyle='width:7%' selectionMode='single' bodyStyle='text-align: center;')
												Column(headerStyle='width:50%' header='Estabelecimento(s)')
													template(#body='props')
														p.aux1
															ul.aux1(style='padding: 10px !important;')
																li(v-for='elem, idx in unifyPacotes(props.data.nm_estabelecimento)' :key='idx' ) <b> {{ elem }} </b>
														p.aux1(v-if='props.data.ds_pacote') <b>{{ props.data.ds_pacote }}</b>
														p.aux1(v-if='props.data.cd_pacote') <b>Cód. próprio: {{ props.data.cd_pacote }}</b>
														p.aux1.aux2(:class="{ restrict: props.data.ie_bloqueado }" style='white-space: pre-wrap' v-if='props.data.ds_motivo_bloqueio') {{ props.data.ds_motivo_bloqueio }}
														div(v-if='props.data.itens_pacote')
															p Lista de procedimentos ( {{ props.data.itens_pacote.length }} )
																span(v-for='item, idx in props.data.itens_pacote' :key='idx' :class="{'item-indisponivel': item.disponivel == false}")
																	br
																	b {{ idx+1 }}. &nbsp;
																	span {{ item.ds_procedimento }} &nbsp;
																	span(v-if='item.nm_subgrupo')
																		b(:class="{'credito-medclub': item.disponivel == true}") | SUBGRUPO: &nbsp;
																		b {{ item.nm_subgrupo }} &nbsp;
																	Button.small.p-button-rounded.p-button-outlined.p-button-warning(v-if="item.ie_hora_marcada" v-tooltip.top="'Procedimento agendável'" icon="jam jam-calendar" type="button")
												Column(headerStyle='width:15%' field='nr_valor_f' bodyStyle='text-align:center' header='Valor')
													template(#body='props')
														span.p-mr-1 {{  $root.formatPrice(+props.data.valor[0].nr_preco) }}
														Button.small.p-button-rounded.p-button-outlined.p-button-success(v-tooltip.top="'Adicionar no orçamento'" icon="jam jam-document" type="button" @click='adicionarOrcamento(props.data)')
												Column(headerStyle='width:15%' bodyStyle='padding: 0' header='Suspenso')
													template(#body='props')
														.cell(:class="{ off: props.data.ie_bloqueado }") {{ props.data.ie_bloqueado ? 'Sim' : 'Não' }}

											.ta-center(v-if="selectedPacote" style="margin-top:10px")
												ProgressSpinner(v-if='waitingCadastroAgenda' strokeWidth='6' :style="{'width': '30px', 'height': '30px'}")
												Button(v-else type="button" label="Agendar" @click='agendaPacotes()' style='width: 100%')
													
														
									
									span(v-else-if="model.ie_tipo_atendimento == 2 || (model.ie_tipo_atendimento == 1 && model.ie_agenda != null)")
										span
											label.form-label Especialidade:
											ProgressBar(v-if='waitingEspecialidades' mode="indeterminate")
											p.ta-center(v-else-if='! options.especialidades.length' style='font-size: 12px;') <b>Sem especialidade disponível.</b>
											Dropdown(
												v-else
												:filter='true'
												v-model='model.cd_especialidade'
												:options='options.especialidades'
												optionLabel='text'
												optionValue='value'
												dataKey='value'
												placeholder='- Selecione -'
											)
												template(#option='props')
													span {{ props.option.textAlt }}

										span(v-if="model.cd_especialidade && model.ie_agenda != false")
											label.form-label Área de Atuação:
											ProgressBar(v-if='waitingAreaDeAtuacao' mode="indeterminate")
											p.ta-center(v-else-if='!options.areas_atuacao.length || !showAreaAtuacao' style='font-size: 12px;') <b>Sem área de atuação disponível.</b>
											Dropdown(
												v-else
												:filter='true'
												:showClear="true"
												v-model='model.cd_area_atuacao'
												:options='options.areas_atuacao'
												optionLabel='ds_area_atuacao'
												optionValue='id'
												dataKey='id'
												@input='getEspecialistasByTag'
												placeholder='- Selecione (opcional) -'
											)


									span(v-if="(model.cd_especialidade || model.cd_procedimento)" )
										label.form-label Estabelecimento:
										ProgressBar(v-if='waitingEstabelecimentos' mode="indeterminate")
										p.ta-center(v-else-if='(!model.cd_especialidade && !model.cd_procedimento) || !options.estabelecimentos.length' style='font-size: 12px;') <b>Sem estabelecimento disponível.</b>

										DataTable.carrinho-datatable(v-else-if="isEorP || (isC && model.ie_agenda == false)" @row-select='model.cd_estabelecimento = $event.data.cd_estabelecimento'
											:value='options.estabelecimentos' dataKey='id' :selection.sync="selectedEstabelecimento")
											Column(headerStyle='width:7%' selectionMode='single' bodyStyle='text-align: center;')
											Column(headerStyle='width:50%' header='Estabelecimento')
												template(#body='props')
													p.aux1
														i(v-if="props.data.possui_integracao == true" style="color:#db7900" class="jam jam-bookmark-plus-f") &nbsp;
														b {{ props.data.nm_estabelecimento }}
														span(v-if='props.data.ds_endereco')  - {{ props.data.ds_endereco }}, {{ props.data.nr_telefone }}
													p.aux1(
														v-if='props.data.ds_observacao'
														:style="{'background-color': !props.data.ie_vende_generico && !props.data.cd_subgrupos?.length ? 'yellow' : ''}"
													)
														b  {{ props.data.ds_observacao }}
														span(v-if='props.data.nm_subgrupo')
															b.credito-medclub &nbsp; | SUBGRUPO: &nbsp;
															b {{ props.data.nm_subgrupo }}
														b(v-if='!props.data.ie_vende_generico && !props.data.cd_subgrupos?.length') &nbsp; - Exige subgrupo.
													p.aux1(v-if='props.data.cd_codigo_proprio') <b>Cód. próprio: {{ props.data.cd_codigo_proprio }}</b>
													p.aux1.aux2(:class="{ restrict: props.data.ie_restrito }" style='white-space: pre-wrap' v-if='props.data.ds_informacoes') {{ props.data.ds_informacoes }}
													div(v-if='props.data.procedimentos')
														p(v-if='props.data.procedimentos') Lista de exames ( {{ calcExamesDisponiveis(props.data.procedimentos) }}  / {{ props.data.procedimentos.length }}&nbsp;)
															Button.small.p-button-rounded.p-button-outlined(v-if='verificaObservacoes(props.data.procedimentos)' icon="jam jam-info" type="button" @click="dialogObservacoes.display = true; dialogObservacoes.procedimentos = props.data.procedimentos")
															span(
																v-for='item, idx in props.data.procedimentos'
																:key='idx'
																:class="{'item-indisponivel': item.disponivel == false}"
																:style="{'background-color': !item.ie_vende_generico && !item.cd_subgrupos?.length && item.disponivel ? 'yellow' : ''}"
															)
																br
																b {{ idx+1 }}. &nbsp;
																span {{ item.ds_observacao }} &nbsp;
																span(v-if='item.nm_subgrupo')
																	b(:class="{'credito-medclub': item.disponivel == true}") | SUBGRUPO: &nbsp;
																	b {{ item.nm_subgrupo }} &nbsp;
																span(v-if='item.disponivel == true') {{ formatPrice(item.nr_valor) }}
																b(v-if='!item.ie_vende_generico && !item.cd_subgrupos?.length && item.disponivel') &nbsp; - Exige subgrupo.
																span(v-if='!item.id') (Indisponível)
																span(v-if='item.ie_restrito') (Suspenso)
																span.container(v-if='item.ie_tipo_agenda === "ALL"')
																	CustomTag(v-if="item.ie_tipo_agenda === 'ALL'" :value="'ALL'" severity="warning")
																	CustomTag(v-if="item.ie_tipo_agenda === 'ALI'" :value="'ALI'" severity="success")
																	CustomTag(v-if="item.ie_tipo_agenda === 'ACL'" :value="'ACL'" severity="danger")
																	CustomTag(v-if="item.ie_tipo_agenda === 'AME'" :value="'AME'")
																	Button.small.p-button-rounded.p-button-outlined.p-button-warning(
																		v-if="item.cd_horarios_procedimento.length"
																		v-tooltip="formatDiasSemana(item.cd_horarios_procedimento)"
																		icon="jam jam-clock"
																		type="button"
																	)
													div.container.mt-1(v-if='!props.data.procedimentos')
														CustomTag(v-if="props.data.ie_tipo_agenda === 'ALL'" :value="'ALL'" severity="warning")
														CustomTag(v-if="props.data.ie_tipo_agenda === 'ALI'" :value="'ALI'" severity="success")
														CustomTag(v-if="props.data.ie_tipo_agenda === 'ACL'" :value="'ACL'" severity="danger")
														CustomTag(v-if="props.data.ie_tipo_agenda === 'AME'" :value="'AME'")
														Button.small.p-button-rounded.p-button-outlined.p-button-warning(
															v-if="props.data.cd_horarios_procedimento.length"
															v-tooltip="formatDiasSemana(props.data.cd_horarios_procedimento)"
															icon="jam jam-clock"
															type="button"
														)

											Column(headerStyle='width:15%' field='nr_valor_f' bodyStyle='text-align:center' header='Valor')
												template(#body='props')
													span.p-mr-1 {{ props.data.nr_valor_f }}
													Button.small.p-button-rounded.p-button-outlined.p-button-success(v-tooltip.top="'Adicionar no orçamento'" icon="jam jam-document" type="button" @click='adicionarOrcamento(props.data)')
											Column(v-if="!(model.ie_tipo_exame === 'L')" headerStyle='width:15%' bodyStyle='padding: 0' header='Hora marcada')
												template(#body='props')
													.cell(:class="{ on: props.data.ie_hora_marcada }") {{ props.data.ie_hora_marcada_f }}
											Column(v-if="!(model.ie_tipo_exame === 'L')" headerStyle='width:15%' bodyStyle='padding: 0' header='Suspenso')
												template(#body='props')
													.cell(:class="{ off: props.data.ie_restrito }") {{ props.data.ie_restrito_f }}

										Dropdown(
											v-else
											:filter='true'
											v-model='model.cd_estabelecimento'
											:options='options.estabelecimentos'
											optionLabel='text'
											optionValue='value'
											dataKey='value'
											placeholder='- Selecione -'
										)
											template(#option="props")
												b {{ props.option.text }} <br>
												span(v-if="props.option.ds_endereco") - {{ props.option.ds_endereco }}, {{ props.option.nr_telefone }} <br/>
												span(v-if="props.option.nr_valor_min") - A partir de
													b.credito-medclub.text-caption {{formatPrice(props.option.nr_valor_min)}}

									.form-label(v-if="isEorP && model.cd_estabelecimento &&selectedEstabelecimento?.medicos_executantes.length")
										DataTable.carrinho-datatable(@row-select='model.cd_especialista = $event.data.id'
											:value='options.medicosExecutantes' dataKey='cd_agenda' :selection.sync="selectedMedicoExecutante")
											Column(selectionMode='single' bodyStyle='text-align: center;' headerStyle='width: 3em')
											Column(headerStyle='width:90%' header='Médico Executante')
												template(#body='props')
													p.aux1
														b {{ props.data.nm_especialista }}
													p.aux1 {{ props.data.nr_conselho }} {{ props.data.uf_conselho }}

									span(v-if="isC && model.ie_agenda != false && model.cd_estabelecimento")
										label.form-label Especialista:
										ProgressBar(v-if='waitingEspecialistasEstabelecimento' mode="indeterminate")
										p.ta-center(v-else-if='!options.especialistasEstabelecimento.length' style='font-size: 12px;') <b>Sem especialista disponível.</b>
										Dropdown(
											v-else
											:filter='true'
											:showClear="true"
											v-model='model.cd_especialista_estabelecimento'
											:options='options.especialistasEstabelecimento'
											optionLabel='text'
											optionValue='value'
											dataKey='value'
											@input='getDatasEspecialista'
											placeholder='- Selecione (opcional) -'
										)

									span(v-if="(isC && model.ie_agenda != false && model.cd_estabelecimento) || (isEorP && model.cd_estabelecimento && ((selectedEstabelecimento?.medicos_executantes?.length && model.cd_especialista) || selectedEstabelecimento?.ie_hora_marcada || selectedEstabelecimento?.ie_tipo_agenda === 'ALL' ))")
										label.form-label Data:
										.ta-center.mt-4(v-if='waitingDatas')
											ProgressSpinner(strokeWidth='2')
										p.ta-center(v-else-if='! options.datas.length' style='font-size: 12px;') <b>Sem data disponível.</b>
										div(v-else style="position:relative")
											Calendar(
												locale='pt-BR'
												:first-day-of-week='-7'
												:attributes="[ { highlight: true, dates: model.dt_agenda } ]"
												:available-dates='options.datas'
												@dayclick='onDayClick'
											)
											span(@click='model.dt_agenda=null;options.datas=[];getDatasEspecialista(model.cd_especialista_estabelecimento)' v-if="model.dt_agenda" style='text-align:center;display:block;width:100%;color:#657786;cursor:pointer;font-size:.88em') limpar data

									span(v-if="isC && model.dt_agenda")
										label.form-label(style='position:relative')
											span Especialista / Valor:
										ProgressBar(v-if='waitingEspecialistas' mode="indeterminate")
										p.ta-center(v-else-if='!options.especialistas.length' style='font-size: 12px;') <b>Selecione uma data.</b>
										DataTable.datatable-epecialistas(v-show='options.especialistas.length' :value='options.especialistas'
											dataKey='value' :selection.sync="selectedEspecialista")
											Column(selectionMode='single' bodyStyle='text-align: center;' headerStyle='width: 3em')
											Column(headerStyle='width:85%' field='text' header='Especialista')
												template(#body='props')
													div.integracaoConsultasContaine
														div.integracaoSimbolo(v-if="props.data.possui_integracao == true")
															i(style="color:#db7900" class="jam jam-bookmark-plus-f") &nbsp;
														div
															div(style="display: flex; align-items: center; justify-content: flex-start;")
																div(v-tooltip.top='"Consulta sem retorno"' style="display: flex; align-items: end")
																	ForbiddenReturn(v-if='!props.data.ie_permite_retorno')
																span <b>{{ props.data.text }}</b><br/>
															span(style='white-space: pre-wrap') {{ props.data.ds_observacao_agenda }}

											Column(headerStyle='width:15%' bodyStyle='text-align:center' field='preco_f' header='Preço')

									span(v-show="(isC && model.cd_especialista) || (isEorP && model.dt_agenda)")
										label.form-label Horários Disponíveis:
										ProgressBar(v-if='waitingHorarios' mode="indeterminate")
										p.ta-center(v-else-if='! Object.keys(options.horarios).length' style='font-size: 12px;') <b>Sem horário disponível.</b>
										div(v-else-if="selectedEstabelecimento.ie_tipo_agenda !== 'ALL'")
											div(v-for="(i_item, i_index) in options.horarios")
												div(v-for="(j_item, j_index) in options.horarios[i_index]" style="margin-bottom:10px;border-bottom:1px solid #eee;padding-bottom:10px;")
													p
														strong Turno: &nbsp;
														span.chip-turno {{j_item[0].hr_inicio}} às {{j_item[0].hr_fim}}

													div.text-caption.ta-right.my-1
														div.ll <div class='caption tl'></div> Livre
														div.ll <div class='caption fe'></div> Encaixado
														div.ll <div class='caption b0'></div> Bloqueado
														div.ll <div class='caption to'></div> Ocupado

													SelectButton.hr_horario(
														v-model='model.cd_horario_agendamento'
														:options='options.horarios[i_index][j_index]'
														optionLabel='text'
														optionValue='value'
														optionDisabled='disabled'
														dataKey='value'
													)
														template(#option='props')
															div(style='font-size: 0' v-tooltip.top="props.option.ds_motivo_bloqueio")
																div.status(:class="{ disabled: props.option.disabled, bloqueado: props.option.status == 'B', encaixado: props.option.ie_encaixado}")
																div.text {{ props.option.text }}

													.ta-right
														Button.mt-2(
															style='font-size: 11px; max-width: 110px;'
															type='button'
															label='Criar horário' icon='jam jam-plus'
															@click='openDialogCriarHorario(j_item[0])'
															)
										div(v-else style="margin-bottom:10px;border-bottom:1px solid #eee;padding-bottom:10px;")
											.p-grid
												.p-col-6.ta-center(v-for="(item, idx) in options.horarios[model.dt_agenda_f]")
													p
														strong Vagas: &nbsp;
														span.chip-turno(:class="item.nr_vagas > 0 ? 'green' : ''") {{ item.nr_vagas }} / {{ item.nr_vagas_total }}

													p(v-if='item.nr_encaixes')
														strong Encaixes: &nbsp;
														span.chip-turno.purple {{ item.nr_encaixes }}

													SelectButton.hr_horario2(
														class="turno"
														v-model='model.cd_horario_agendamento'
														:options="[item]"
														optionLabel='text'
														optionValue='value'
														dataKey='value'
														@input='vagasHorario = item.nr_vagas'
													)

									span.ta-center(v-if='$refs.buscarPaciente')
										.box-submit.my-4(v-if='btnAgendar && !waitingForm && !isPC')
											ProgressSpinner(v-if='waitingAgendar' strokeWidth='6')
											div(v-else)
												Button.p-button-danger.mr-2(v-if="selectedEstabelecimento.ie_tipo_agenda === 'ALL'" type="button" label="Seguir sem agendar" @click='gerarEncaixe()' :style="{ 'width': vagasHorario ? '38%' : '100%' }")
												Button(v-if="!(selectedEstabelecimento.ie_tipo_agenda === 'ALL' && !vagasHorario)" label='Agendar' type="submit" :style="{ 'width': selectedEstabelecimento.ie_tipo_agenda === 'ALL' ? '58%' : '100%' }")

									span(v-if="['CH'].includes(this.model.ie_tipo_agenda)")
										AgendamentoCheckup

						span(v-show='agendamentos.length || orcamentos.length' ref='reservados')
							Panel.mb-2(header='Orçamento' :toggleable='true' :collapsed='true')
								div
									.p-grid.p-fluid
										.p-col-6(v-for='orcamento, idx in orcamentos' :key='idx')
											Card.card-agendamento
												template(slot="content")
													.remove-button(v-if='mostrarNoEmpresa && cliente'
														@click='orcamentos.splice(idx, 1)') <i class='jam jam-close'></i>
													div(v-if='orcamento.ds_observacao')
														h1.text1 {{ orcamento.ds_observacao }}
														p.text1 <b>Local: </b> {{ orcamento.nm_estabelecimento }}
														p.text1(v-if='orcamento.nr_valor_f')
															b Preço:
																span.valor {{ orcamento.nr_valor_f }}
													div(v-else)
														p.text1 <b>Local: </b> {{ orcamento.nm_estabelecimento }}
														p.text1(v-if='orcamento.nr_valor_total_disponivel')
															b Preço:
																span.valor {{ formatPrice(orcamento.nr_valor_total_disponivel) }}
														p.text1(v-if='orcamento.procedimentos') Lista de exames ( {{ calcExamesDisponiveis(orcamento.procedimentos) }}  / {{ orcamento.procedimentos.length }} )
															span(v-for='item, idx in orcamento.procedimentos' :key='idx' :class="{'item-indisponivel': item.disponivel == false}")
																br
																b {{ idx+1 }}. &nbsp;
																span {{ item.ds_observacao }} &nbsp;
																span(v-if='item.disponivel == true') {{ formatPrice(item.nr_valor) }}

									.p-grid.p-fluid.p-justify-center(style="border-bottom:1px solid #eee;" ref='acoesReservados')
										.p-grid.p-col-12.p-justify-center
											.p-col-4.ta-center
												b.form-label Número de Cards:
													span.valor {{ orcamentos.length }}
												b.form-label Itens do Orçamento:
													span.valor {{ itensTotaisOrcamento }}
											.p-col-4.ta-center
												b.form-label Valor Total:
													.valor(ref='vTotal') {{ orcamentos_sum() }}
										.p-grid.p-col-12.p-justify-center(v-show='cliente')
											.p-col-4
												Button(type='button' icon='jam jam-printer' label="Imprimir" :disabled='!orcamentos.length' @click='imprimirOrcamento()')
											.p-col-4(v-if="mostrarNoEmpresa")
												Button.p-button-danger(type='button' icon='jam jam-trash' label="Remover todos" :disabled='!orcamentos.length' @click='dialogApagar=true; removerOrcamentos = true')
									.p-grid.p-fluid(ref="btnNovoAtendimento" v-show='cliente')
										.p-col-5.mt-2
											Button.p-button-success(type='button' icon='jam jam-shopping-cart' label="Adicionar ao carrinho" :disabled='!orcamentos.length' @click='reservarOrcamento()')

							Panel(header='Reservados')
								.waitingRemoverTodos(v-if='waitingRemoverTodos')
									ProgressSpinner(strokeWidth='2')
									p.ta-center(style='color: #888') <b>Removendo...</b>
								.waitingNovoAtendimento(v-else-if='waitingNovoAtendimento')
									ProgressSpinner(strokeWidth='2')
									p.ta-center(style='color: #888') <b>Atualizando...</b>
								div(v-else)
									fieldset(v-for='paciente, idx in pacientesCarrinho' :key="idx" :style="{border: '1px solid #c8c8c8', 'margin-top': idx > 0 ? '1em' : '0'}")
										legend.title-fieldset {{ paciente }}
										.p-grid.p-fluid
											.p-col-6(v-for='agendamento in agendamentos.filter(item => item.info.nm_paciente == paciente)' :key='agendamento.id')
												Card.card-agendamento(v-if="!agendamento.ie_pacote")
													template(slot="content")
														.remove-button(v-if='waitingRemover == 0 && mostrarNoEmpresa && cliente'
															@click='removerAgendamento(agendamento)') <i class='jam jam-close'></i>
														h1.text1(v-if='!agendamento.info.cd_procedimentos') {{ agendamento.info.ds_procedimento }} <span v-if='agendamento.info.nm_subgrupo'> <span class="credito-medclub"> | SUBGRUPO: </span> {{ agendamento.info.nm_subgrupo }} </span> <i v-if='agendamento.info.ie_agenda' class="jam jam-info" style="color: #007ad9" v-tooltip.top="`${agendamento.info.nm_especialista} - ${agendamento.info.dt_agenda_f} ${agendamento.info.hr_agenda_f}`"></i>
														.waitingRemover(v-if='waitingRemover == agendamento.id')
															ProgressSpinner(strokeWidth='2')
														div(v-else)
															br(v-if='!agendamento.info.cd_procedimentos')
															p.text1 <b>Paciente: </b> {{ agendamento.info.nm_paciente }}
															p.text1 <b>Local: </b> {{ agendamento.info.nm_estabelecimento }}
															div(v-if="agendamento.info.ie_tipo_agenda === 'C'" style='margin-top: 3px')
																p.text1 <b>Especialidade: </b> {{ agendamento.info.nm_especialidade.length ? agendamento.info.nm_especialidade : agendamento.info.ds_procedimento }}
															//- div(v-else style='margin-top: 3px')
															//- 	p.text1 <b>Procedimento: </b> {{ agendamento.info.ds_procedimento }}
															div(v-if="agendamento.info.nm_especialista_solicitante" style='margin-top: 3px')
																p.text1 <b>Solicitante: </b> {{ agendamento.info.nm_especialista_solicitante }}
															p.text1(v-if='agendamento.info.preco_f')
																b Preço:
																	span.valor {{ agendamento.info.preco_f }}
															p.text1(v-if='agendamento.info.cd_procedimentos') Lista de exames ( {{ agendamento.info.cd_procedimentos.length }} )
																span(v-for='item, idx in agendamento.info.cd_procedimentos' :key='idx')
																	br
																	b {{ idx+1 }}. &nbsp;
																	span {{ item.nm_procedimento }} &nbsp;

															div(v-if="['E', 'P', 'L'].includes(agendamento.info.ie_tipo_agenda) && cliente && !agendamento.info.cd_procedimentos")
																p.text1
																	b Quantidade:
																	span.valor(v-if='mostrarNoEmpresa')
																		InputNumber( style="width:35px;text-align:center;" @focusout='incrementarQuantidade(agendamento, agendamento.info.nr_quantidade)' @keyup.enter.native='$event.target.blur()' :disabled="waitingQuantidade[agendamento.id]" :useGrouping="false" v-model="agendamento.info.nr_quantidade" buttonLayout="horizontal" :step="1"
																			decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal")
																		span.quantidade
																			span(v-if='waitingQuantidade[agendamento.id]')
																				ProgressSpinner.qtd-spinner(strokeWidth='2')
																			span(v-else)
																				i.btn-add.jam.jam-plus(@click='incrementarQuantidade(agendamento,"+1")')
																				i.btn-rem.jam.jam-minus(v-if='agendamento.info.nr_quantidade > 1' @click='incrementarQuantidade(agendamento,"-1")')
																				i.btn-rem-disabled.jam.jam-minus(v-else)
																	b.valor(v-else v-text="agendamento.info.nr_quantidade")
												Card.card-agendamento(v-else)
													template(slot="content")
														.remove-button(v-if='waitingRemoverPacote == 0 && mostrarNoEmpresa && cliente'
															@click='excluirPacotes(agendamento.id)') <i class='jam jam-close'></i>
														h1.text1(style="margin-bottom: 15px") {{ agendamento.cd_pacote }} <i class="jam jam-info" style="color: #007ad9; cursor: pointer" @click="dialogProcedimentos = true; dialogProcedimentos_data = agendamento" v-tooltip.top="'Ver procedimentos'"></i>
														.waitingRemover(v-if='waitingRemoverPacote == agendamento.id')
															ProgressSpinner(strokeWidth='2')
														div(v-else)
															p.text1 <b>Paciente: </b> {{ agendamento.info.nm_paciente }}
															p.text1 <b>Pacote: </b> {{ agendamento.ds_pacote }}
															p.text1(v-if='agendamento?.valores?.length')
																b Preço:
																	span.valor {{ $root.formatPrice(agendamento.info.preco) }}
											

									.p-grid.p-fluid.p-justify-center(style="border-bottom:1px solid #eee;" ref='acoesReservados')
										.p-grid.p-col-12.p-justify-center
											.p-col-4.ta-center
												b.form-label Número de Cards:
													span.valor {{ itensDistintos }}
												b.form-label Itens do Carrinho:
													span.valor {{ itensTotais }}
											.p-col-4.ta-center
												b.form-label Valor Total:
													.valor(ref='vTotal') {{ agendamentos_sum() }}
										.p-grid.p-col-12.p-justify-center(v-show='cliente')
											.p-col-4
												Button(type='button' icon='jam jam-printer' label="Imprimir" :disabled='!agendamentos.length' @click='dialogOpcoesImpressao=true')
											.p-col-4(v-if="mostrarNoEmpresa")
												Button.p-button-danger(type='button' icon='jam jam-trash' label="Remover todos" :disabled='!agendamentos.length' @click='dialogApagar=true')
									.p-grid.p-fluid(ref="btnNovoAtendimento" v-show='cliente')
										.p-col-5.mt-2
											Button.p-button-secondary(type='button' icon='jam jam-refresh' label="Novo atendimento" @click='novoAtendimento()')
										span.p-grid.p-col-7(v-if='!tipo_atendimento' style='display:contents')
											.p-col-2.mt-2
												label &nbsp;
											.p-col-5.mt-2
												Button.p-button-success(type='button' icon='jam jam-coin' label="Pagar" :disabled='!agendamentos.length' @click='pagarAtendimento()')
										span.p-grid.p-col-7(v-else style='display:contents')
											.p-col-2.mt-2
												label &nbsp;
											.p-col-5.mt-2
												Button.p-button-success(type='button' icon='jam jam-check' label="Autorizar" :disabled='!agendamentos.length' @click='dialogAutorizarEmpresa = true')


</template>

<style lang="scss">
	.agendamento-salvar {
		.dialogApagarLaboratorial{
			width: 450px;
		}
		.form-atendimento{
			.form-label {
				margin-top: 10px ;
			}
		}
		.text-caption {
			font-size: 16px;
			.ll{
				display: inline-block;
				margin-right:20px;
			}
			.caption {
				display: inline-block;
				height: 16px;
				width: 16px;
				background-color: #888;
				margin-right: 5px;
				border-radius: 10px;
				&.sa { background-color: #718096; }
				&.tl { background-color: #38a169; }
				&.hv { background-color: #d69e2e; }
				&.to { background-color: #f7de08; }
				&.b0 { background-color: #e53e3e; }
				&.fe { background-color: #9b67d5; }
			}
		}
		.integracaoConsultasContainer {
			display: flex;
			.integracaoSimbolo {
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.semRetornoSimbolo {
				width: 20px;
				height: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		.nomeUsuario2 {
			color: #000;
			font-weight: 600;
			background-color: #ddd;
		}
		.dialogAtendimentos {
			width: 96%;
			max-width: 720px;
		}
		.carrinho-datatable {
			max-height: 500px;
			overflow-y: auto;
			.cell {
				padding: 16px 0;
				text-align: center;
				background-color: #e5e5e5;
				&.on  { background-color: #e4f8e1; }
				&.off  {
					color: #FFF;
					font-size: 16px;
					font-weight: 700;
					background-color: #ef0000;
				}
			}
			.p-highlight .cell {
				background-color: #007ad9 !important;
			}
			.cell-procedimento-checkup {
				margin: 0;
				&.strike {
					text-decoration: line-through;
				}
			}
		}
		.datatable-epecialistas {
			max-height: 300px; overflow-y: auto;
			td { font-size: 13px; }
		}
		.aux1 {
			margin: 0;
		}
		.aux2 {
			color: #0a0a0a;
			font-size: 13px;
			background-color: #FFFFE0;
			font-weight: 700;
			padding: 2px 6px;
			border-radius: 3px;
			margin-top: 4px;
			&.restrict {
				color: #FFF;
				font-size: 16px;
				background-color: #ef0000;
			}
		}
		.chip-turno {
			padding: 0.375rem 0.75rem;
			font-weight: 700;
			background: #ef0000;
			color: #fff;
			border-radius: 16px;
			&.green{
				background: #28a745;
			}
			&.purple{
				background: #9b67d5;
			}
		}
		.waitingRemoverTodos, .waitingNovoAtendimento {
			text-align: center;
			.p-progress-spinner {
				margin: 40px 0;
				width: 60px;
				height: 60px;
			}
		}
		.waitingRemover {
			text-align: center;
			.p-progress-spinner {
				margin: 8px 0;
				width: 41px;
				height: 41px;
			}
		}
		.waitingNomeUsuario {
			width: 25px;
			height: auto;
			margin: 16px 0;
		}
		.__box-imprimir {
			width: 100%;
			.img-logo {
				max-width: 160px;
				margin: 20px 0;
			}
			.title {
				font-weight: 700;
				font-size: 15px;
			}
			table {
				width: 100%;
				border-collapse: collapse;
				td {
					padding: 2px 4px;
					font-size: 13px;
					border: 1px solid #000;
				}
			}
			@media screen {
				display: none;
			}
		}
		.card-agendamento {
			position: relative;
			.remove-button {
				position: absolute;
				top: 10px; right: 10px;
				cursor: pointer;
				background-color: #aaa;
				color: #fff;
				font-size: 0;
				border-radius: 50%;
				i {
					font-size: 16px;;
				}
			}
			.remove-button {
				position: absolute;
				top: 10px; right: 10px;
				cursor: pointer;
				background-color: #aaa;
				color: #fff;
				font-size: 0;
				border-radius: 50%;
				i {
					font-size: 16px;;
				}
			}
			.quantidade{
				display:inline-block;
				margin-left:20px;
			}
			.btn-add, .btn-rem, .btn-rem-disabled {
				margin-left:5px;
				cursor: pointer;
				background-color: #aaa;
				color: #fff;
				padding: 10%;
				text-align:center;
				border-radius: 50%;
				i {
					font-size: 16px;
				}
			}
			.btn-add{
				background-color:#38a169;
			}
			.btn-rem{
				background-color:#e53e3e
			};
			.btn-rem-disabled{
				cursor: not-allowed;
			}
			.qtd-spinner{
				top: 10px;
				right: 10px;
				height: 35px;
				width: 35px;
			};
			.text-title, .text1 {
				margin: 0;
			}
			.text-title {
				font-size: 15px;
				margin-bottom: 10px;
			}
			.text1 {
				font-size: 13px;
				margin-left: 16px;
			}
		}
		.p-selectbutton {
			&.ie_tipo_agenda {
				.p-button { width: 25% !important; }
			}
			&.ie_tipo_atendimento {
				.p-button { width: 50% !important; }
			}
			&.hr_horario {
				.status {
					display: inline-block;
					vertical-align: middle;
					width: 16px;
					height: 16px;
					border-radius: 50%;
					margin-right: 10px;
					background-color: #38a169;
					border: 1px solid #298051;
					&.encaixado {
						background-color: #9b67d5 !important;
						border: 1px solid #6d40a0 !important;
					}
					&.bloqueado {
						background-color: #e53e3e !important;
						border: 1px solid #c73434 !important;
					}
					&.disabled {
						background-color: #f7de08;
						border: 1px solid #d3be00;
					}
				}
				.text {
					display: inline-block;
					vertical-align: middle;
					font-size: 14px;
					font-weight: 700;
				}
				.p-button {
					padding: 6px;
					width: 20% !important;
				}
			}
			&.hr_horario2 {
				.text {
					display: inline-block;
					vertical-align: middle;
					font-size: 14px;
					font-weight: 700;
				}
				.p-button {
					padding: 6px;
					width: 80% !important;
				}
			}
			.p-button {
				.p-button-text {
					font-size: 12px;
				}
			}
		}
		.vc-container {
			margin: 20px auto;
		}
		.p-progressbar {
			margin: 18px 0;
		}
		.box-submit {
			padding: 0;
			.p-progress-spinner {
				height: 27px;
			}
		}
		.dialogCriarHorario {
			width: 95%; max-width: 400px;
			.p-inputmask {
				text-align: center;
				font-size: 16px;
			}
		}
		.dialogProcedimentos {
			width: 70%; 
			max-width: 800px;
		}
		.dialog-reservado,
		.dialog-opcoes-impressao {
			width: 600px;
			max-width: 96%;
			.waiting-imprimir {
				width: 30px;
				height: auto;
			}
		}
		.medico-solicitante-select {
			.p-dropdown-clear-icon {
				color: #ef0000;
			}
		}
		.copy-button {
			color: black;
			background-color: #ddd;
			border: 1px solid #a6a6a6;
			&:hover {
				background-color: #a6a6a6 !important;
				border: 1px solid #ddd !important;
			}
		}
		.valor {
			color: #657786;
			font-size: 16px;
		}
		.credito-medclub {
			color: #3c60ba !important;
		}
		.item-indisponivel {
			color:#e91224 !important;
		}
		.small {
			font-size: 10px;
			margin-left: 4px;
		}
		.title-fieldset {
			color: #007ad9;
			font-size: 14px;
			font-weight: 700;
		}
		.container {
			display: flex;
			gap: 6px;
		}
	}
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
	import AutoComplete from 'primevue/autocomplete';
	import Panel from 'primevue/panel'
	import ProgressBar from 'primevue/progressbar'
	import ProgressSpinner from 'primevue/progressspinner'
	import Button from 'primevue/button'
	import Dropdown from 'primevue/dropdown'
	import SelectButton from 'primevue/selectbutton'
	import Calendar from 'v-calendar/lib/components/calendar.umd'
	import BuscarPaciente from './../Paciente/BuscarPaciente'
	import Imprimir from './Imprimir'
	import Dialog from 'primevue/dialog'
	import InputMask from 'primevue/inputmask'
	import InputText from 'primevue/inputtext'
	import InputNumber from 'primevue/inputnumber';
	import Card from 'primevue/card'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import AgendamentoCheckup from './Salvar-agendamentoCheckup'
	import Tooltip from "primevue/tooltip"
	import OrigemDropdown from "./../OrigemAtendimento/OrigemDropdown"
	import CustomDropdown from "./../CustomComponents/CustomDropdown.vue"
	import CustomMultiSelect from '../CustomComponents/CustomMultiSelect.vue';
	import ForbiddenReturn from "@/components/CustomComponents/icons/ForbiddenReturn";
	import DialogObservacoes from './DialogObservacoes.vue';
	import DialogPacotes from './DialogPacotes.vue';
	import ConfirmDialog from '../CustomComponents/ConfirmDialog.vue';
	import CustomTag from '../CustomComponents/CustomTag.vue';
	import DialogAgendaLivreLimitada from './DialogAgendaLivreLimitada.vue';

	import moment from 'moment'
	import * as _ from 'lodash'
	import DialogObsImprimir from '../PrecoProcedimentos/DialogObsImprimir.vue';
	import { fixTelefoneFormat, pCalendarLocale_ptbr, removeAcentos, copyToClipboard, formatPrice } from './../../utils'
	import { Agendamento, AreaAtuacao, Especialista, MedEmpresa, Pacote } from '../../middleware'
	export default {
		mounted() {
			const ta = sessionStorage.getItem('tipo_atendimento')
			if (this.$route.name == 'atendimento_med_empresa' && ta)
				this.tipo_atendimento = ta
			this.beforeMount()
			if(this.idAtendimento) {
				this.getAtendimento(this.idAtendimento)
			}
		},
		components: {
            OrigemDropdown, CustomDropdown, ForbiddenReturn, AutoComplete, Panel, ProgressBar,
            Button, SelectButton, Dropdown, InputMask, Column, DialogObservacoes, DialogObsImprimir,
            Calendar, ProgressSpinner, BuscarPaciente, Imprimir, Dialog, Card, DataTable, InputText,
            InputNumber, AgendamentoCheckup, CustomMultiSelect, ConfirmDialog, CustomTag, DialogAgendaLivreLimitada,
			DialogPacotes
        },
		directives: { tooltip: Tooltip },
		beforeRouteLeave (to, from, next) {
			sessionStorage.removeItem('tipo_atendimento')
			if (this.cliente) Agendamento.finalizarAtendimento(this.cliente.id).then(() => {})
			next()
		},
		props: ['atendimentoSelected'],
		watch: {
			cliente: function (val) {
				if (val) this.getMedicoSolicitanteOptions();
			},
			'model.ie_tipo_agenda': function (val) {
				if (val) {
					if (this.isEorP){
						this.model.ie_tipo_atendimento = 1
						this.model.ie_agenda = null
						if (this.model.ie_tipo_agenda === 'P') this.getProcedimentos()
						else {
							this.model.cd_especialidade = null
							this.options.estabelecimentos = []
							this.model.cd_estabelecimento = null
							this.selectedEstabelecimento = {}
							this.model.cd_estabelecimento
						}
					} else if (this.isC) {
						this.model.ie_tipo_atendimento = null
						this.model.cd_especialista = null
						this.getEspecialidades()
					} else if (this.isPC){
						this.model.ie_tipo_atendimento = 1;
						this.model.ie_agenda = null;
						this.model.ie_tipo_pacote = null;
						this.limparPacotes();
					}
				this.model.cd_estabelecimento= null
				this.model.cd_especialidade = this.model.cd_procedimento = null;
				}
			},
			'model.ie_tipo_pacote': function(){
				this.limparPacotes();
			},
			'model.ie_tipo_exame': function (val){
				if(val) {
					this.model.cd_estabelecimento = null
					this.getProcedimentos()
				}
			},
			'model.ie_tipo_atendimento': function (val) {
                this.model.ie_agenda = val === 2 || null
				this.model.cd_especialidade = null
				this.model.cd_estabelecimento = null
				if (val == 2) this.getEspecialidades()
			},
			'model.ie_agenda': function(val) {
				this.showMedicoExecutante = false
				if (val != null) this.getEspecialidades()
			},
			'model.cd_especialidade': function (val) {
				this.model.cd_area_atuacao = null
				if(val) {
					this.getEstabelecimentos()
					if(this.model.ie_tipo_agenda != false)
						this.getAreasAtuacao()
				} else {
					this.model.cd_area_atuacao = null
					this.model.cd_estabelecimento = null
				}
			},
			'model.cd_area_atuacao': function() {
				if(this.options.especialistasEstabelecimento.length)
					this.getEspecialistasEstabelecimento(this.model.cd_area_atuacao)
			},
			'model.cd_estabelecimento': function (val) {
				this.model.dt_agenda = null
				this.model.cd_especialista = null
				this.model.cd_especialista_estabelecimento = null
				if(val){
					const [hm, me] = [this.selectedEstabelecimento.ie_hora_marcada, this.selectedEstabelecimento.medicos_executantes]
					if(hm != false || me.length || this.selectedEstabelecimento.ie_tipo_agenda === "ALL")
						this.getDatas()
					if (this.isC && (this.model.ie_agenda != false || (this.model.ie_agenda == false && this.hm))) {
						this.getEspecialistasEstabelecimento()
						if(val && !this.ie_agenda)
							this.getAreasAtuacao()
					}
				}
			},
			'model.cd_procedimento': function (val) {
				if (!val || val.length == 0) {
					this.model.id_procedimento = null
					this.model.cd_procedimento = null
				}
				else {
					this.ignorarConfirmacaoLaboratorial = false
					if (this.model.ie_tipo_exame === "L") this.model.id_procedimento = this.model.cd_procedimento
					else {
						let find = this.options.procedimentos.find(procedimento => procedimento.value == val)
						this.model.id_procedimento = find.value
					}
					this.getEstabelecimentos()
				}
			},
			'options.especialistas': function (val) {
				if(val.length == 1)
					this.selectedEspecialista = val[0]
			},
			'selectedEspecialista': function(val) {
				if(val?.value)
					this.model.cd_especialista = val.value
			},
			'model.dt_agenda': function (val) {
				this.horarioSelecionado = {}
				this.model.dt_agenda_f = moment(this.model.dt_agenda).format('YYYY-MM-DD')
				if (this.isEorP && this.selectedEstabelecimento.ie_tipo_agenda !== "ALL")
					this.getHorarios()
				else if (this.isC) {
					this.selectedEspecialista = {}
					if(val)
						this.getEspecialistas()
					else
						this.model.cd_especialista = null
				} else {
					this.model.cd_horario_agendamento = null
				}
			},
			'model.cd_especialista': function (val) {
				if (_.isInteger(val)) {
					if(this.showMedicoExecutante) {
						this.model.dt_agenda = null
						if (val > 0) {
							this.waitingDatas = true
							this.selectedEstabelecimento.ie_hora_marcada = true;

							Agendamento.getDatasCadastro({
								ie_tipo_agenda: this.model.ie_tipo_agenda,
								ie_telemedicina: this.model.ie_tipo_atendimento == 2,
								cd_estabelecimento: this.model.cd_estabelecimento,
								cd_especialista: val,
								cd_procedimento: this.selectedEstabelecimento.id,
							}).then(response => {
								this.waitingDatas = false
								if (response.status == 200) {
									this.options.datas = []
									response.data.forEach(data => {
										this.options.datas.push(moment(data.dt_agenda)._d)
									})
								}
								if (this.isEorP)
									this.getHorarios()
							})
						} else {
							this.selectedEstabelecimento.ie_hora_marcada = false
							this.options.datas = []
						}
					} else
						this.getHorarios()

				} else {
					this.showMedicoExecutante = false;
					this.selectedMedicoExecutante = {};
				}
			},
			'atendimentoSelected': async function (val) {
				if(val){
					this.dialogAtendimentos_selected = val;
					this.mostrarNoEmpresa = this.tipo_atendimento == 'E' ? this.dialogAtendimentos_selected?.ie_status == "A" : true
					await this.onAtendimentoSelect()
				}
			},
			'agendamentos': function (val) {
				if (val){
					this.pacientesCarrinho = val.reduce((acc, pessoa) => {
						if (!acc.includes(pessoa.info.nm_paciente)) acc.push(pessoa.info.nm_paciente)
						return acc
					}, [])
				}
			},
		},
		data () {
			return {
				model: {
					ie_reservar: 'R',
					ie_tipo_agenda: 'C',
					ie_tipo_pacote: null,
					nm_busca_pacote: null,
					ie_tipo_atendimento: null,
					cd_estabelecimento: null,
					id_procedimento: null,
					cd_procedimento: null,
					cd_especialista: null,
					cd_especialista_estabelecimento: null,
					cd_especialidade: null,
					cd_pacote: null,
					dt_agenda: null,
					dt_agenda_f: null,
					cd_horario_agendamento: null,
					cd_area_atuacao: null,
					cd_especialista_solicitante: null,
					nr_quantidade:1,
					ie_agenda: null,
					ie_tipo_exame: null,
					ie_encaixado: false,
				},
				dialogReservados: false,
				dialogProcedimentos: false,
				dialogProcedimentos_data: {},
				horarioSelecionado:{},
				options: {
					ie_tipo_agenda: [
						{ value: 'C', text: 'Consulta' },
						{ value: 'E', text: 'Exame' },
						{ value: 'P', text: 'Procedimento' },
						// { value: 'CH', text: 'Check-up' }
						{ value: 'PC', text: 'Pacote' }
					],
					ie_tipo_pacote:[
						{ value: 'P', text: 'Pacote' },
						{ value: 'CK', text: 'Check-up' },
					],
					ie_tipo_atendimento: [
						{ value: 1, text: 'Presencial' },
						{ value: 2, text: 'Vídeo' }
					],
					ie_agenda: [
						{ value: true, text: 'Com Agenda'},
						{ value: false, text: 'Sem Agenda'}
					],
					ie_tipo_exame: [
						{ value: 'I', text: 'Imagem' },
						{ value: 'L', text: 'Laboratorial' }
					],
					tipos_checkup: [],
					estabelecimentos: [],
					procedimentos: [],
					especialistas: [],
					pacotes: [],
					tiposPacotes: [],
					medicosSolicitantes: [],
					especialistasEstabelecimento: [],
					especialidades: [],
					medicosExecutantes: [],
					datas: [],
					horarios: [],
					areas_atuacao: [],
				},
				waitingAreaDeAtuacao: false,
				waitingCadastroAgenda: false,
				selectedEstabelecimento: {},
				selectedPacote: null,
				dialogPacotes: false,
				dialogPacoteBloqueado: false,
				selectedMedicoExecutante: {},
				selectedEspecialista: {},
				selectedEspecialistaDropdown: false,
				ptbr: pCalendarLocale_ptbr,
				waitingAgendar: false,
				waitingEstabelecimentos: false,
				pagePacote: 1,
				lastPagePacote: false,
				waitingPacotes: false,
				waitingNextPagePacotes: false,
				showAreaAtuacao: false,
				pageProcedimento: 1,
				lastPageProcedimento: false,
				waitingProcedimentos: false,
				waitingNextPageProcedimentos: false,
				waitingEspecialidades: false,
				waitingEspecialistas: false,
				waitingEspecialistasEstabelecimento: false,
				waitingDatas: false,
				waitingHorarios: false,
				waitingRemover: 0,
				waitingRemoverTodos: false,
				waitingNovoAtendimento: false,
				waitingNomeUsuario: false,
				waitingImprimir: false,
				waitingAtendimento: false,
				waitingRemoverPacote: 0,
				medicoSolicitante: {
					page: 1,
					lastPage: false,
					waiting: false,
					waitingNextPage: false,
				},
				showMedicoExecutante: false,
				waitingQuantidade: {},
				agendamentos: [],
				orcamentos: [],
				removerOrcamentos: false,
				estabelecimentosAgendados: {},
				dialogApagar: false,
				dialogAtendimentos: false,
				dialogAtendimentos_data: [],
				dialogAtendimentos_selected: null,
				dialogOpcoesImpressao: false,
				dialogCriarHorario: false,
				dialogCriarHorario_data: {},
				dialogCriarHorario_base: {},
				dialogEmpresaSuspensa: false,
				dialogApagarLaboratorial: false,
				dialogAutorizarEmpresa: false,
				ignorarConfirmacaoLaboratorial: false,
				motivoSuspensaoEmpresa: "",
				dataImpressao: moment().format('DD/MM/YYYY - HH:mm:ss'),
				motivo_bloqueio:"",
				valorTotal: 0,
				cliente: null,
				titular: null,
				atendimentoOrigem: null,
				desativarCliente: false,
				mostrarNoEmpresa: true,
				atendimentoSelectedTable: false,
				tipo_atendimento: null,
				idAtendimento: _.toInteger(this.$route.params.id),
				routerFrom: '',
				dialogObservacoes: {
					display: false,
					procedimentos: [],
				},
				selecionados: [],
				dialogObsImprimir: false,
				pacientesCarrinho: [],
				dialogAgendaLL: false,
				procALL: null,
				vagasHorario: null,
			}
		},
		computed: {
			/**
			 * Verifica se o tipo de atendimento é Exame ou Procesimento
			 */
			isEorP: function () {
				return ['E', 'L', 'P'].includes(this.model.ie_tipo_agenda)
			},
			/**
			 * Verifica se o tipo de atendimento é Consulta
			 */
			isC: function () {
				return this.model.ie_tipo_agenda == 'C'
			},
			isPC: function() {
				return this.model.ie_tipo_agenda == 'PC'
			},
			btnAgendar: function () {
				return !this.waitingRemoverTodos
						&& this.$refs.buscarPaciente.cd_pessoa_fisica
						&& (this.model.cd_horario_agendamento
						|| (!this.selectedEstabelecimento.ie_hora_marcada
						&& this.selectedEstabelecimento?.medicos_executantes?.length === 0
						&& this.selectedEstabelecimento.ie_tipo_agenda !== "ALL")
						|| (this.selectedEstabelecimento.ie_tipo_agenda === "ALL"
						&& this.model.cd_horario_agendamento)
						|| this.model.cd_especialista === 0)
			},
			waitingForm: function () {
				return this.waitingAreaDeAtuacao
					|| this.waitingEstabelecimentos
					|| this.waitingProcedimentos
					|| this.waitingNextPageProcedimentos
					|| this.waitingPacotes
					|| this.waitingNextPagePacotes
					|| this.waitingEspecialidades
					|| this.waitingEspecialistas
					|| this.waitingEspecialistasEstabelecimento
					|| this.waitingDatas
					|| this.waitingHorarios
					|| this.waitingRemover
					|| this.waitingRemoverTodos
					|| this.waitingNovoAtendimento
					|| this.waitingNomeUsuario
					|| this.waitingImprimir
			},
			consultaAvulsa: function() {
				return this.isC && this.model.ie_agenda == false
			} ,
			saldoTitular: function() {
				return this.tipo_atendimento == 'E'
							? this.titular?.empresa_associada_f.nr_saldo
							: this.titular?.nr_saldo
						?? 0
			},
			itensDistintos: function() {
				return this.agendamentos.length
			},
			itensTotais: function() {
				return this.agendamentos.reduce((acc, item) => {
					if(item.ie_pacote) return acc + 1
					return acc + item.info.nr_quantidade
				}, 0);
				// return _.sumBy(this.agendamentos, 'info.nr_quantidade')
			},
			itensTotaisOrcamento: function() {
				let aux = 0
				this.orcamentos.forEach( item => {
					if ( item.procedimentos?.length )
						item.procedimentos.forEach( proc => {
							if(proc.disponivel) aux++
						})
					else aux++
				})
				return aux
			}
		},
		methods: {
			autorizarEmpresa() {
				MedEmpresa.autorizarCompra({cd_atendimento: this.cliente.atendimento.id}).then(response => {
					if ([200, 201, 204].includes(response.status))	{
						this.$toast.success('Guias autorizadas!', { duration: 3000 })
						if(response.data.cd_transacao_financeira) {
							window.open(`/transacoes-financeiras/visualizar/${response.data.cd_transacao_financeira}/`, '_blank')
						}
						this.$router.push({path: '/med-empresa/atendimentos'})
					} else if (response.status == 400) {
						if (response.data.non_field_errors)
							response.data.non_field_errors.forEach(errorMsg => {
								this.$toast.error(errorMsg, { duration: 3000 })
							})
						else Object.values(response.data).forEach(errorMsg => {
							if (typeof errorMsg == 'object')
								this.$toast.error(errorMsg[0], { duration: 3000 })
							else this.$toast.error(errorMsg, { duration: 3000 })
						})
					}
				})
			},
			gerarEncaixe() {
                this.model.ie_encaixado = true
				this.vagasHorario = null
                this.handleSubmit()
            },
			async reservarLaboratorialALL(params) {
				let model = {}
				this.selectedEstabelecimento = params
				model.ie_reservar = 'R'
				model.cd_especialista = params.cd_especialista
				model.cd_especialista_estabelecimento = params.cd_especialista_estabelecimento
				model.dt_agenda = params.dt_agenda
				model.cd_area_atuacao = params.cd_area_atuacao
				model.ie_tipo_agenda = 'E'
				model.ie_tipo_atendimento= params.ie_tipo_atendimento
				model.ie_agenda = params.ie_agenda
				model.cd_especialidade = params.cd_especialidade
				model.ie_tipo_exame = params.ie_tipo_exame
				model.cd_estabelecimento = params.cd_estabelecimento
				model.cd_procedimento = params.cd_procedimento
				model.cd_horario_agendamento = params.cd_horario_agendamento
				model.cd_especialista_solicitante = params.cd_especialista_solicitante
				model.ie_encaixado = params.ie_encaixado
				model.nr_quantidade = 1
				await this.handleSubmit(true, model)
				this.$toast.success('Horário reservado!', { duration: 3000 })
			},
			formatDiasSemana(datas){
				const diasSemana = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

				let aux = []
				let horarios = []
				let result = []

				datas.forEach(data => {
					data.ar_dias_semana.forEach(dia => {
						let idx = aux.findIndex(item => item.dia_semana == dia)
						if(idx !== -1) aux[idx].horarios.push({hr_inicio: data.hr_inicio, hr_fim: data.hr_fim, nr_vagas: data.nr_vagas})
						else aux.push({dia_semana: dia, horarios: [{hr_inicio: data.hr_inicio, hr_fim: data.hr_fim, nr_vagas: data.nr_vagas}]})
					})
				})
				aux.sort((a, b) => a.dia_semana - b.dia_semana )
				aux.forEach(data => {
					const diaFormatado = diasSemana[data.dia_semana]
					data.horarios.forEach(horario => {
						horarios.push(`${horario.hr_inicio} até ${horario.hr_fim} (${horario.nr_vagas} vagas/dia)`)
					})
					result.push(`${diaFormatado}: ${horarios.join(' e ')}`)
					horarios = []
				})
				return result.join(';\n')
			},
			verificaObservacoes(data) {
                let aux = data.find(item => item.ds_informacoes)
                if (aux) return true
                return false
            },
			adicionarOrcamento (data) {
				data['ie_tipo_agenda'] = this.model.ie_tipo_agenda
				if(this.model.ie_tipo_exame) data['ie_tipo_exame'] = this.model.ie_tipo_exame
				if(this.model.ie_tipo_atendimento != null) data['ie_tipo_atendimento'] = this.model.ie_tipo_atendimento
				if(this.model.ie_agenda != null) data['ie_agenda'] = this.model.ie_agenda
				if(this.model.cd_especialidade != null) data['cd_especialidade'] = this.model.cd_especialidade
				if(this.model.cd_especialista_solicitante) data['cd_especialista_solicitante'] = this.model.cd_especialista_solicitante
				this.orcamentos.push(data)
			},
			confirmaApagarLaboratorial(event) {
				if(this.model.cd_procedimento && this.model.ie_tipo_exame === 'L' && !this.ignorarConfirmacaoLaboratorial) {
					event.stopPropagation();
					event.preventDefault();
					this.dialogApagarLaboratorial = true
				}
			},
			calcExamesDisponiveis(proc) {
				let aux = 0
				proc.forEach(item => {
					if(item.disponivel)
						aux++
				})
				return aux
			},
			findProcedimentoById(id) {
                return this.options.procedimentos.find(item => {
                    return item.value == id
                })?.textAlt || ''
            },
			findHorario(cd_horario_agendamento){
				for(let i in this.options.horarios){
					for(let j in this.options.horarios[i]){
						for(let k in this.options.horarios[i][j]){
							if(this.options.horarios[i][j][k].value === cd_horario_agendamento)
							return this.options.horarios[i][j][k]
						}

					}
				}
				return undefined
			},
			getAreasAtuacao() {
				this.waitingAreaDeAtuacao = true;
				if(this.model.cd_estabelecimento) {
					AreaAtuacao.findByEstabelecimento({
						id: this.model.cd_estabelecimento,
					})
						.then(response => {
							this.waitingAreaDeAtuacao = false;
							if (response.status === 200) {
								this.options.areas_atuacao = response.data
							}
						})
				} else {
					const configs = { usarCidade: this.model.ie_tipo_atendimento === 1 }
					AreaAtuacao.findAllEstabelecimento({}, configs)
						.then(response => {
							this.waitingAreaDeAtuacao = false;
							if (response.status === 200) {
								this.options.areas_atuacao = response.data
							}
						})
				}
			},
			handleBack(id) {
				if(this.tipo_atendimento == 'E') {
					if ( _.isInteger(id) ) {
						this.$router.push({path: `/med-empresa/atendimento/${id}`}),
						this.$router.go()
					} else
						this.$router.push({path: '/med-empresa/atendimentos'})
				} else {
					this.$router.push({path: `/agendamento/salvar/${id ?? 0}`})
					this.$router.go()
				}
			},
			async reservarOrcamento() {
				let model = {}
				for (const item of this.orcamentos) {
					this.selectedEstabelecimento = item
					model.ie_reservar = 'R'
					model.cd_especialista = item.cd_especialista
					model.cd_especialista_estabelecimento = item.cd_especialista_estabelecimento
					model.dt_agenda = item.dt_agenda
					model.cd_area_atuacao = item.cd_area_atuacao
					model.ie_tipo_agenda = item.ie_tipo_agenda
					model.ie_tipo_atendimento= item.ie_tipo_atendimento
					model.ie_agenda = item.ie_agenda
					model.cd_especialidade = item.cd_especialidade
					model.ie_tipo_exame = item.ie_tipo_exame
					model.cd_estabelecimento = item.cd_estabelecimento
					model.cd_procedimento = item.cd_procedimento
					model.cd_horario_agendamento = item.cd_horario_agendamento
					model.cd_especialista_solicitante = item.cd_especialista_solicitante
					model.nr_quantidade = 1
					await this.handleSubmit(true, model)
				}
				this.$toast.success('Horário reservado!', { duration: 3000 })
			},
			async handleSubmit(verified=false, modelAlt) {
				let model = {}
				let consultaAvulsa = this.consultaAvulsa
				if (modelAlt) {
					model = Object.assign({}, modelAlt)
					consultaAvulsa = (model.ie_tipo_agenda === 'C' && model.ie_agenda == false)
				} else model = Object.assign({}, this.model)

				if (model.ie_tipo_agenda === 'C' && !model.ie_agenda) model.ie_tipo_agenda = "P" //Foi definido no back que consultas avulsas é de qualquer outro tipo de agenda entre ['E', 'L', 'P']

				const isEorP = ['E', 'L' ,'P'].includes(model.ie_tipo_agenda)

				if (!verified && model.ie_tipo_exame === "L") {
					this.procALL = {...this.selectedEstabelecimento, procedimentos: this.selectedEstabelecimento.procedimentos.filter(item => (item.ie_tipo_agenda === "ALL") && item.disponivel)}
					this.dialogAgendaLL = this.selectedEstabelecimento.procedimentos?.findIndex(item =>
						item.ie_tipo_agenda === "ALL"
					) !== -1;
					if (this.dialogAgendaLL && this.selectedEstabelecimento.procedimentos.filter(item => (item.ie_tipo_agenda !== "ALL") && item.disponivel).length === 0) return;
				}

				if (!verified && isEorP) {
					if (model.ie_tipo_exame === "L") {
						this.dialogReservados = this.agendamentos.findIndex(item =>
							this.selectedEstabelecimento?.procedimentos?.findIndex(procedimento =>
								item.info?.cd_procedimento_estabelecimento === procedimento.id
							) !== -1
						) !== -1;
					} else {
						this.dialogReservados = this.agendamentos.findIndex(item =>
							item.info?.cd_procedimento_estabelecimento === this.selectedEstabelecimento?.id
						) !== -1;
					}
					if (this.dialogReservados) return;
				}

				let dataSend = Object.assign({}, model)
				let deleteKeys = ['ie_tipo_atendimento', 'dt_agenda', 'cd_especialista', 'cd_estabelecimento', 'ie_tipo_agenda', 'cd_tipo_checkup']
				if (!isEorP) deleteKeys.push('cd_especialista_solicitante')
				Object.keys(dataSend).forEach(key => { if (dataSend[key] == null || deleteKeys.includes(key)) delete dataSend[key] })
				if (this.$refs.buscarPaciente) dataSend.cd_pessoa_fisica = this.$refs.buscarPaciente.cd_pessoa_fisica
				if (this.cliente) dataSend.cd_atendimento = this.cliente.atendimento.id

				let _returnSuccess = (responseData) => {
					let agendamento = {
						id: responseData.id,
						paciente: this.$refs.buscarPaciente.model,
						info: Object.assign({}, model),
						dataSend
					}

					agendamento.info.id = responseData.id
					agendamento.info.nm_paciente = agendamento.paciente.nm_pessoa_fisica
					agendamento.info.cd_paciente = {id:responseData.cd_paciente}
					agendamento.info.ie_tipo_agenda = model.ie_tipo_agenda
					agendamento.info.nm_especialista_solicitante = responseData.nm_especialista_solicitante
					agendamento.info.cd_procedimento_estabelecimento = responseData.cd_procedimento_estabelecimento
					agendamento.info.nm_endereco_estabelecimento = responseData.nm_endereco_estabelecimento
					agendamento.info.nr_telefone_estabelecimento = responseData.nr_telefone_estabelecimento
					agendamento.info.nm_subgrupo = responseData.nm_subgrupo

					if (isEorP || consultaAvulsa) {
						let estabelecimento = this.selectedEstabelecimento
						agendamento.info.nm_estabelecimento = estabelecimento.nm_estabelecimento
						agendamento.info.preco_f = this.$root.formatPrice(responseData.nr_valor*responseData.nr_quantidade)
						agendamento.info.preco = responseData.nr_valor*responseData.nr_quantidade
						agendamento.quantidade = responseData.nr_quantidade
					} else {
						let estabelecimento = this.options.estabelecimentos.find(elem => elem.value == agendamento.info.cd_estabelecimento)
						agendamento.info.nm_estabelecimento = estabelecimento.text
					}

					agendamento.info.ie_tipo_agenda_f =
						this.options.ie_tipo_agenda.find(elem => elem.value == agendamento.info.ie_tipo_agenda).text

					agendamento.info.ie_tipo_atendimento_f =
						agendamento.info.ie_tipo_atendimento ?
							this.options.ie_tipo_atendimento.find(elem => elem.value == agendamento.info.ie_tipo_atendimento).text
							: 'Presencial'

					if ( agendamento.info.cd_horario_agendamento )
						agendamento.info.hr_agenda_f = this.findHorario(agendamento.info.cd_horario_agendamento).text

					let find = this.options.procedimentos.find(elem => elem.value == agendamento.info?.cd_procedimento)

					agendamento.info.ds_procedimento = responseData?.ds_observacao
						??	find?.nm_procedimento
							??	''

					if (agendamento.info.cd_especialidade && !consultaAvulsa) {
						let find = this.options.especialidades.find(elem => elem.value == agendamento.info.cd_especialidade)
						agendamento.info.nm_especialidade = find.text
					}
					if (agendamento.info.cd_especialista && !this.showMedicoExecutante) {

						let find = this.options.especialistas.find(elem => elem.value == agendamento.info.cd_especialista)
						agendamento.info.nm_especialista = find.text
						agendamento.info.preco_f = this.$root.formatPrice(responseData.nr_valor)
						agendamento.info.preco = responseData.nr_valor
					}

					if (agendamento.info.dt_agenda) agendamento.info.dt_agenda_f =
						moment(agendamento.info.dt_agenda).format('DD/MM/YYYY')

					this.agendamentos.push(agendamento)
					// console.log("this.agendamentos",this.agendamentos)
					this.model.cd_procedimento = null
					this.model.cd_especialidade = null
					this.model.cd_especialista = null
					this.model.cd_estabelecimento = null
					this.model.cd_horario_agendamento = null
					this.model.ie_encaixado = false
					if (this.$refs.dropdownProcedimentos && !modelAlt) {
						this.$refs.dropdownProcedimentos.filterValue = ''
						this.$refs.dropdownProcedimentos.$el.click()
					}
				}

				if ((isEorP && !model.cd_horario_agendamento) || consultaAvulsa) dataSend.cd_horario_agendamento = 0

				if (isNaN(dataSend.cd_horario_agendamento)) {
					let lastOriginalHorario = this.horarioSelecionado.parent

					dataSend = {
						cd_pessoa_fisica: dataSend.cd_pessoa_fisica,
						hr_agenda: dataSend.cd_horario_agendamento,
						dt_agenda: moment(model.dt_agenda).format('YYYY-MM-DD'),
						cd_especialidade: dataSend.cd_especialidade,
						cd_agenda: lastOriginalHorario.cd_agenda,
						cd_dia_atendimento: lastOriginalHorario.cd_dia_atendimento,
						cd_especialista_solicitante: dataSend.cd_especialista_solicitante || null,
						cd_atendimento: this.cliente.atendimento.id
					}

					if(lastOriginalHorario.ie_encaixado) {
						dataSend['ie_encaixado'] = true
					}

					if( isEorP ){
						dataSend['cd_procedimento'] = this.selectedEstabelecimento.id
					}

					this.waitingAgendar = true
					await Agendamento.encaixarHorario(dataSend).then(response => {
						this.waitingAgendar = false
						if (response.status == 200) _returnSuccess(response.data)
						else if (response.status == 400) {
							if (response.data.cd_horario_agendamento) {
								if (typeof response.data.cd_horario_agendamento == 'string')
									this.$toast.error(response.data.cd_horario_agendamento, { duration: 3000 })
								else response.data.cd_horario_agendamento.forEach(msg => this.$toast.error(msg, { duration: 3000 }))

								this.getHorarios()
							} else {
								_.each(response.data, err => {
									this.$toast.error(err)
								})
							}
						}
					})

				} else {
					if (isEorP || consultaAvulsa) {
						if (model.ie_tipo_exame === "L") {
							dataSend['cd_procedimentos'] = []
							this.selectedEstabelecimento.procedimentos.forEach(item => { if(item.disponivel && item.ie_tipo_agenda !== "ALL") dataSend['cd_procedimentos'].push(item.cd_subgrupos?.length ? item.id + '.' + item.cd_subgrupos : item.id.toString()) })
						}
						else if (!consultaAvulsa)
							dataSend['cd_procedimento'] = this.selectedEstabelecimento.cd_subgrupos?.length ? this.selectedEstabelecimento.id + '.' + this.selectedEstabelecimento.cd_subgrupos : this.selectedEstabelecimento.id.toString()
                        else
							dataSend['cd_procedimento'] = this.selectedEstabelecimento.id
						delete dataSend.id_procedimento
					}
					if (this.selectedEstabelecimento?.ie_tipo_agenda === 'ALL') {
						dataSend['dt_agenda'] = moment(model.dt_agenda).format('YYYY-MM-DD')
						dataSend['cd_horario_procedimento'] = model.cd_horario_agendamento
						dataSend['cd_horario_agendamento'] = 0
						delete model.cd_horario_agendamento
					}
					this.waitingAgendar = true
					let metodo = model.ie_tipo_exame === "L" ? 'reservarHorarioCheckup' : 'reservarHorario'
					await Agendamento[metodo](dataSend).then(response => {
						this.waitingAgendar = false
						if (response.status == 200)	{
							if(model.ie_tipo_exame === "L" && Array.isArray(response.data)) response.data.forEach( item => _returnSuccess(item) )
							else _returnSuccess(response.data)
							if (!modelAlt)
								this.$toast.success('Horário reservado!', { duration: 3000 })
							this.selectedEstabelecimento = {}
						} else if (response.status == 400) {
							if (response.data.non_field_errors)
								response.data.non_field_errors.forEach(errorMsg => {
									this.$toast.error(errorMsg, { duration: 3000 })
								})
							else Object.values(response.data).forEach(errorMsg => {
								if (typeof errorMsg == 'object')
									this.$toast.error(errorMsg[0], { duration: 3000 })
								else this.$toast.error(errorMsg, { duration: 3000 })
							})
						}
					})
				}
			},
			async selecionarCliente (force) {

				const bpPessoaFisica = this.$refs.buscarPaciente2.cd_pessoa_fisica
				if (! bpPessoaFisica) {
					this.$toast.error('Selecione um cliente', { duration: 3000 })
					return 0
				}
				if (this.atendimentoOrigem === null) {
					this.$toast.error('Campo "Origem" é obrigatório', { duration: 3000 })
					return 0
				}

				const params = { cd_pessoa_fisica: bpPessoaFisica }
				const ea_id = this.$refs.buscarPaciente2?.model?.empresa_associada_f?.id

				if(this.validaEmpresaAssociada(ea_id)) params['cd_empresa'] = ea_id

				this.waitingNomeUsuario = true
				const atendimento = this.tipo_atendimento == 'E' ? MedEmpresa : Agendamento

				await atendimento.getAtendimentos(params).then(response => {
					let atendimentosAnteriores = []
					if ([200, 201, 204].includes(response.status)) atendimentosAnteriores = response.data
					if (! atendimentosAnteriores.length || force) {
						const atendimentoDataSend = {
							...params,
							cd_origem_atendimento: this.atendimentoOrigem,
						}

						atendimento.criarAtendimento(atendimentoDataSend).then(async response => {
							if ([200, 201, 204].includes(response.status)) {
								this.mudaTamanho(response.data)
								const bp2 = this.$refs.buscarPaciente2.model

								this.$refs.buscarPaciente.filtro.selected.by = 'id'
								this.$refs.buscarPaciente.filtro.selected.value = (bp2.id).toString()

								const params = {}
								if(this.tipo_atendimento == 'E') params['cd_empresa_associado'] = bp2.empresa_associada_f.id

								await this.$refs.buscarPaciente.buscar(bp2.id, params)

								const bpList = this.$refs.buscarPaciente.list

								this.cliente = this.$refs.buscarPaciente.model
								this.cliente.cd_pessoa_fisica = bpList.find(e => e.id == bpPessoaFisica)

								this.cliente.atendimento = response.data
								this.titular = this.$refs.buscarPaciente.titular
								let rota = this.tipo_atendimento == 'E' ? '/med-empresa/atendimento/' : '/agendamento/salvar/'
								this.$router.push(rota + `${response.data.id}`)
								// this.$router.push({path:`/med-empresa/atendimento/${response.data.id}/`})
							} else {
								_.forEach(response.data, err=>{
									if(_.isString(err)) this.$toast.error(err)
									else {
										_.forEach(err, e => {
											this.$toast.error(e)
										})
									}
								})
							}
							this.waitingNomeUsuario = false
						})

					} else {
						this.$toast.info('Cliente possui atendimentos em aberto', { duration: 3000 })
						atendimentosAnteriores.forEach(atendimento => {
							let nr_valor_total = 0
							atendimento.horarioagendamento_set.forEach(ag => {
								ag.nr_valor_total_f =
									new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
										.format(ag.nr_valor_total)
								if (ag.nr_cpf) ag.nr_cpf_f = ag.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
								nr_valor_total += parseFloat(ag.nr_valor_total)
							})
							atendimento.dt_realizado = moment(atendimento.dt_atualizado).format('DD/MM/YYYY')
							atendimento.hr_realizado = moment(atendimento.dt_atualizado).format('HH:mm')
							atendimento.nr_valor_total_f = this.formatPrice(nr_valor_total)
						})
						this.dialogAtendimentos_data = atendimentosAnteriores
						this.dialogAtendimentos = true
						this.waitingNomeUsuario = false
					}
				})
			},
			async onAtendimentoSelect () {
				if(!this.idAtendimento) {
					this.$router.push(`/agendamento/salvar/${this.dialogAtendimentos_selected.id}`)
					this.$router.go()
				} else {
					this.atendimentoSelectedTable = true
					let agendamentos = []
					let tipo_agenda = { 'C': 'Consulta', 'E': 'Exame', 'L': 'Laboratorial', 'CH': 'Procedimento (check-up)'}
					this.dialogAtendimentos_selected.horarioagendamento_set.forEach(ag => {
						ag.ie_tipo_agenda_f = tipo_agenda[ag.cd_checkup ? 'CH' :  ag.ie_tipo_agenda]
						ag.preco = parseFloat(ag.nr_valor_total)
						ag.preco_f = ag.nr_valor_total_f ?? this.formatPrice(ag.preco)

						if( ag.cd_procedimentos.length > 0 ){
							ag.cd_procedimento_estabelecimento = ag.cd_procedimentos[0].cd_procedimento_estabelecimento
							ag.ds_procedimento = '[' + ag.cd_procedimentos[0].cd_cbhbm + '] ' + ag.ds_procedimento
						}

						if( !(ag.cd_procedimentos.length > 1) ) delete ag.cd_procedimentos

						agendamentos.push({
							id: ag.id,
							paciente: this.$refs.buscarPaciente.model,
							info: { ...ag, ie_agenda: ag.dt_agenda && ag.nm_especialista? true : false, dt_agenda_f: moment(ag.dt_agenda).format("DD/MM/YYYY"), hr_agenda_f: ag.hr_agenda.slice(0, -3) },
							dataSend: ag
						})
					})

					this.mudaTamanho(this.dialogAtendimentos_selected)
					this.agendamentos = [];
					let auxPacotes = [];
					agendamentos.forEach(item=> {
						if(item.info.cd_pacote){
							let auxIndex = auxPacotes.findIndex(i => i.id == item.info.cd_pacote?.id)
							if(auxIndex == -1){
								auxPacotes.push({
									...item.info.cd_pacote,
									ie_pacote: true,
									cd_procedimentos: [item],
									info: {
										cd_paciente: item.info.cd_paciente,
										cd_atendimento: item.info.cd_atendimento,
										nr_valor: item.info.nr_valor_total,
										nm_paciente: item.info.nm_paciente,
										nr_cpf: item.info.nr_cpf,
										ie_tipo_agenda: item.info.ie_tipo_agenda,
										ie_status: item.info.ie_status,
										preco: item.info.preco 
									}
								})
							}else {
								auxPacotes[auxIndex].cd_procedimentos.push(item)
								auxPacotes[auxIndex].info.preco = auxPacotes[auxIndex].info.preco + item.info.preco
							}
						}else{
							this.agendamentos.push(item)
						}
					})
					this.agendamentos = [...this.agendamentos, ...auxPacotes];
					// this.agendamentos = agendamentos
					// let auxPacotes = [];
					// console.log("agendamentos antes", this.agendamentos)
					// this.agendamentos.forEach(item=> {
					// 	if(item.cd_pacote){
					// 		let auxIndex = auxPacotes.findIndex(i => i.id == item.cd_pacote.id)
					// 		if(auxIndex == -1){
					// 			auxPacotes.push({
					// 				...item.info.cd_pacote,
					// 				cd_paciente: item.info.cd_paciente,
					// 				cd_procedimentos: item.info.cd_procedimentos,
					// 				cd_atendimento: item.info.cd_atendimento,
					// 				nr_valor: item.info.nr_valor_total,
					// 				nm_paciente: item.info.nm_paciente,
					// 				nr_cpf: item.info.nr_cpf,
					// 				ie_tipo_agenda: item.info.ie_tipo_agenda,
					// 				ie_status: item.info.ie_status
					// 			})
					// 		}else 
					// 			auxPacotes[auxIndex].cd_procedimentos.push(item.cd_procedimentos)
					// 	}
					// })
					// this.agendamentos = [...this.agendamentos, ...auxPacotes];
					
					const ea_id = this.tipo_atendimento == 'E' ? this.dialogAtendimentos_selected.cd_empresa_associado : null
					const pf = this.dialogAtendimentos_selected.cd_pessoa_fisica
					const params = {}
					const id = pf.id

					this.$refs.buscarPaciente.filtro.selected.by = 'id'
					this.$refs.buscarPaciente.filtro.selected.value = id.toString()


					if(this.validaEmpresaAssociada(ea_id)) params['cd_empresa_associado'] = ea_id

					await this.$refs.buscarPaciente.buscar(id, params)

					this.cliente = this.$refs.buscarPaciente.model
					this.cliente.cd_pessoa_fisica = this.$refs.buscarPaciente.cd_pessoa_fisica
					this.cliente.atendimento = this.dialogAtendimentos_selected

					this.titular = this.cliente.responsavel ?? this.cliente
					this.dialogAtendimentos = false
				}
			},
			imprimirReservados (termica=false) {
				if (termica) {
					this.waitingImprimir = true
					Agendamento.imprimeAtendimento(this.cliente.atendimento.id).then(response => {
						this.waitingImprimir = false
						if (response.status == 200) {
							if (response.data.detail) {
								this.$toast.error(response.data.detail, { duration: 3000 })
							} else {
								let blob = new Blob([response.data], { type: 'text/html; charset=utf-8' })
								let fd = new FormData()
								fd.append('file', blob)
								fetch('http://localhost:9990/imprimir', { method: 'POST', body: fd })
									.then(() => this.dialogOpcoesImpressao = false)
									.catch(() => this.$toast.error("Sem conexão com impressora térmica."))
							}
						}
					})
				} else {
					// obter uma lista de estabelecimentos
					this.estabelecimentosAgendados = {}
					for (let agendamento of this.agendamentos) {
						if (!(agendamento.info.cd_estabelecimento in this.estabelecimentosAgendados)) {
							Object.assign(this.estabelecimentosAgendados, {
								[agendamento.info.cd_estabelecimento]:  {
									'cd_estabelecimento': agendamento.info.cd_estabelecimento,
									'nm_estabelecimento': agendamento.info.nm_estabelecimento,
									'ds_contatos': agendamento.info.nr_telefone_estabelecimento,
									'nm_endereco': agendamento.info.nm_endereco_estabelecimento
								}
							});
						}
					}
					this.$nextTick(() => {
						window.print();
						this.dialogOpcoesImpressao = false;
					});
				}
			},
			formatPrice,
			async novoAtendimento () {
				this.waitingNovoAtendimento = true
				if(this.tipo_atendimento != 'E') await Agendamento.finalizarAtendimento(this.cliente.id)
				this.waitingNovoAtendimento = false
				this.handleBack(0)
			},
			pagarAtendimento(){

				this.$router.push({path:"/caixa/listar-guias", query:{pg:1, id:this.cliente.atendimento.id}})
			},
			removerAgendamento (agendamento) {
				if (agendamento.dataSend) {
					this.waitingRemover = agendamento.id
					agendamento.dataSend.ie_reservar = 'L'
					agendamento.dataSend.cd_horario_agendamento = agendamento.id
					delete agendamento.dataSend.cd_procedimento
					delete agendamento.dataSend.cd_procedimentos
					Agendamento.reservarHorario(agendamento.dataSend).then(response => {
						if (response.status == 200) {
							this.agendamentos = this.agendamentos.filter(agendamento => agendamento.id != this.waitingRemover)
							this.$toast.success('Agendamento removido', { duration: 3000 })
						} else if (typeof response?.data?.detail == 'string') {
							this.$toast.error(response.data.detail, { duration: 4000 })
						}
						this.waitingRemover = 0
					})
				}
			},
			removerTodos () {
				if ( this.removerOrcamentos ) {
					this.orcamentos = []
					this.removerOrcamentos = false
					this.dialogApagar = false
				} else {
					this.waitingRemoverTodos = true
					let _remover = (idx) => {
						if (idx == this.agendamentos.length) {
							this.waitingRemoverTodos = false
							this.dialogApagar = false
							this.agendamentos = []
							this.$toast.success('Agendamentos removidos', { duration: 3000 })
							return 0
						}
						const dataSend = this.agendamentos[idx].ie_pacote? {
							id: this.agendamentos[idx].id,
							cd_atendimento: this.idAtendimento,
							cd_pessoa_fisica: this.$refs.buscarPaciente.cd_pessoa_fisica,
							ie_reservar: "L",
							cd_procedimentos_estabelecimentos: this.agendamentos.find(item => item.id == this.agendamentos[idx].id).cd_procedimentos.map(i => ({
								id: i.info.cd_procedimento_estabelecimento,
								horario_id: i.id
							}))
						} : null
						if(!this.agendamentos[idx].ie_pacote) {
							this.agendamentos[idx].dataSend.ie_reservar = 'L'
							this.agendamentos[idx].dataSend.cd_horario_agendamento = this.agendamentos[idx].id
						}
						const auxFuncao = this.agendamentos[idx].ie_pacote? 'removerPacote' : 'reservarHorario'
						Agendamento[auxFuncao](!this.agendamentos[idx].ie_pacote? this.agendamentos[idx].dataSend : dataSend).then(() => {
							_remover(++idx)
						})
					}; _remover(0)
				}
			},
			onEndScrollProcedimentos(filterValue) {
				if (!this.waitingProcedimentos && !this.waitingNextPageProcedimentos && !this.lastPageProcedimento) {
					this.getProcedimentos(filterValue, ++this.pageProcedimento)
				}
			},
			onEndScrollPacotes(filterValue) {
				if (!this.waitingPacotes && !this.waitingNextPagePacotes && !this.lastPagePacote) {
					this.getPacotes(filterValue, ++this.pagePacote)
				}
			},
			getProcedimentos (nm_procedimento, page) {
				if (nm_procedimento && nm_procedimento.trim().length < 2) return

				nm_procedimento = nm_procedimento || ""
				this.pageProcedimento = page || 1
				if (this.pageProcedimento == 1) this.lastPageProcedimento = false;
				let per_page = 10

				if(this.model.ie_tipo_exame !== "L") this.model.cd_procedimento = null
				this.model.cd_especialidade = null

				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento']
				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					page: this.pageProcedimento,
					per_page: per_page,
					nm_procedimento: nm_procedimento,
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					ie_atende_medclub_empresa: this.tipo_atendimento == 'E'
				}
				if(this.model.ie_tipo_exame) params['ie_grupo'] = this.verificaTipoExame()
				let configs = { ignorarEstabelecimento: true, usarCidade: true }

				let waitingKey = (this.pageProcedimento === 1) ? "waitingProcedimentos" : "waitingNextPageProcedimentos";
				this[waitingKey] = true;

				Agendamento.getProcedimentosCadastro(params, configs).then(response => {
					this[waitingKey] = false
					if (response.status == 200) {
						let procedimentos = response.data.map(procedimento => {
							return {
								value: procedimento.cd_subgrupos?.length ? procedimento.id + '.' + procedimento.cd_subgrupos : procedimento.id.toString(),
								text: `${ procedimento.cd_procedimento } - ${ procedimento.ds_procedimento } / ${ procedimento.nm_procedimento } / ${ procedimento.ds_sigla } / ${ procedimento.ds_sinonimia } / ${ procedimento.ds_procedimento_m } / ${ procedimento.nm_subgrupo }`,
								textAlt: `${ procedimento.cd_procedimento } - ${ procedimento.ds_procedimento }`,
								nm_procedimento: procedimento.nm_procedimento,
								ds_sinonimia: procedimento.ds_sinonimia,
								ds_sigla: procedimento.ds_sigla,
								nm_subgrupo: procedimento.nm_subgrupo,
								id: procedimento.id,
								nr_menor_preco: procedimento.nr_menor_preco,
							}
						})
						if (this.pageProcedimento === 1) {
							this.options.procedimentos = []
							if (response.data.length && this.model.ie_tipo_exame !== 'L') this.options.procedimentos.push({ value: null, text:'- Selecione -', textAlt: '- Selecione -' })
							this.options.procedimentos.push(...procedimentos)
						} else {
							this.options.procedimentos.push(...procedimentos)
						}
						if (response.data.length < per_page) this.lastPageProcedimento = true;
					}
					if(this.model.ie_tipo_exame !== "L") this.getEstabelecimentos()
				})
			},
			getEspecialidades () {
				this.options.especialidades = []
				this.model.cd_especialidade = null
				this.model.cd_procedimento = null

				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento']
				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					ie_sem_agenda: this.consultaAvulsa,
					ie_atende_medclub_empresa: this.tipo_atendimento == 'E'
				}

				let configs = { usarCidade: !params.ie_telemedicina }

				this.waitingEspecialidades = true
				Agendamento.getAllEspecialidades(params, configs).then(response => {
					this.waitingEspecialidades = false
					if (response.status == 200) {
						this.options.especialidades = []
						if (response.data.length) this.options.especialidades.push({ value: null, text: '- Selecione -', textAlt: '- Selecione -'  })

						response.data.forEach(especialidade => {
							especialidade.nm_especialidade_m = removeAcentos(especialidade.nm_especialidade)
							especialidade.cd_especialidade = especialidade.id;
							this.options.especialidades.push({
								value: especialidade.cd_especialidade,
								text: `${ especialidade.nm_especialidade } / ${ especialidade.nm_especialidade_m }`,
								textAlt: especialidade.nm_especialidade
							})
						})
					}
					this.getEstabelecimentos()
				})
			},
			getEstabelecimentos () {
				this.options.estabelecimentos = []
				this.model.cd_estabelecimento = null
				this.selectedEstabelecimento = {}

				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento']

				if (this.isEorP) fields.push('cd_procedimento')
				else if (this.isC) fields.push('cd_especialidade')

				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					ie_sem_agenda: this.consultaAvulsa,
					ie_atende_medclub_empresa: this.tipo_atendimento == 'E'
				}
				let configs = { ignorarEstabelecimento: true, usarCidade: !params.ie_telemedicina }

				if (this.model.cd_especialidade) params.cd_especialidade = this.model.cd_especialidade
				if (this.model.cd_procedimento) params.cd_procedimento = this.model.cd_procedimento

				if (this.isEorP || (this.isC && this.model.ie_agenda == false)) {
					let params2 = Object.assign({}, params)
					if(this.model.ie_tipo_agenda != 'C')
						params2.cd_procedimento = this.model.id_procedimento
					configs.usarCidade = true
					if(this.model.ie_tipo_exame === "L") {
						Agendamento.getEstabelecimentosLaboratoriais(params2, configs).then(response => {
							if (response.status == 200) {
								response.data.forEach(item => {
									item.id = item.cd_estabelecimento
									item.ie_hora_marcada = false
									item.nr_valor_f = this.formatPrice(item.nr_valor_total_disponivel)
									if (item.nr_telefone) item.nr_telefone = fixTelefoneFormat(item.nr_telefone)
								})
								this.options.estabelecimentos = response.data
							}
						})
					}
					else {
						Agendamento.getEstabelecimentosProcedimentos(params2, configs).then(response => {
							if (response.status == 200) {
								this.options.estabelecimentos = []
								response.data.forEach(item => {
									item.ie_hora_marcada_f = item.ie_hora_marcada ? 'Sim' : 'Não'
									item.ie_restrito_f = item.ie_restrito ? 'Sim' : 'Não'
									item.nr_valor_f = this.formatPrice(item.nr_valor)
									if (item.nr_telefone) item.nr_telefone = fixTelefoneFormat(item.nr_telefone)
								})
								this.options.estabelecimentos = response.data
							}
						})
					}
				} else {
					Agendamento.getEstabelecimentos(params, configs).then(response => {
						if (response.status == 200) {
							this.options.estabelecimentos = []
							if (response.data.length) this.options.estabelecimentos.push({ value: null, text: '- Selecione -' })
							response.data.forEach(estabelecimento => {
								this.options.estabelecimentos.push({
									value: estabelecimento.cd_estabelecimento,
									text: estabelecimento.nm_estabelecimento,
									ds_endereco: estabelecimento.ds_endereco,
									nr_telefone: fixTelefoneFormat(estabelecimento.nr_telefone),
									nr_valor_min: estabelecimento.nr_valor_min
								})
							})
						}
						this.showAreaAtuacao = true;
						this.getDatas()
					})
				}
				this.waitingEstabelecimentos = false
			},
			verificaTipoExame(){
				if(this.model.ie_tipo_exame === "L") return 'L';
				return 'I';
			},
			getDatasEspecialista (val) {

				this.model.dt_agenda = null
				this.options.especialistas = []
				if( val ){
					this.selectedEspecialistaDropdown = val
				} else{
					this.selectedEspecialistaDropdown = false
				}
				this.getDatas()
			},
			getDatas () {
				this.options.datas = []
				this.options.horarios = []
				this.model.cd_horario_agendamento = null
				this.model.dt_agenda = null

				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento', 'cd_estabelecimento']

				if (this.isC) fields.push('cd_especialidade')

				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					cd_estabelecimento: this.model.cd_estabelecimento,
					ie_atende_medclub_empresa: this.tipo_atendimento == 'E'
				}

				if (this.model.cd_especialidade) params.cd_especialidade = this.model.cd_especialidade
				if (this.model.cd_especialista_estabelecimento) params.cd_especialista = this.model.cd_especialista_estabelecimento
				if (this.model.cd_procedimento) params.cd_procedimento = this.model.cd_procedimento

				if (this.isEorP) {
					params.cd_procedimento = this.selectedEstabelecimento.id
				}

				this.waitingDatas = true
				this.options.medicosExecutantes = [];
				if(this.selectedEstabelecimento.medicos_executantes &&
					this.selectedEstabelecimento?.medicos_executantes?.length > 0) {

						this.options.medicosExecutantes = this.selectedEstabelecimento.medicos_executantes;
						var check = this.options.medicosExecutantes.some(medico => medico.id == 0);
						if(!check) {
							this.options.medicosExecutantes.unshift({
								id: 0,
								cd_agenda: 0,
								nm_especialista: 'Sem Médico Executante'
							})
						}

						this.showMedicoExecutante = true;
						this.waitingDatas = false
				} else
				// if (this.model.ie_agenda || this.selectedEstabelecimento.ie_hora_marcada || this.model.ie_tipo_atendimento == 2)
				{
					if (this.selectedEstabelecimento.ie_tipo_agenda === 'ALL') {
						Agendamento.getDatasAgendaLL(params).then(response => {
							if (response.status == 200) {
								this.options.datas = []
								response.data.forEach(data => {
									this.options.datas.push(moment(data.dt_agenda)._d)
									if (!this.options.horarios[data.dt_agenda]) {
										this.options.horarios[data.dt_agenda] = []
									}
									this.options.horarios[data.dt_agenda].push({
										value: data.id,
										text: `De ${moment(data.hr_inicio, 'HH:mm:ss', true).format('HH:mm')} até ${moment(data.hr_fim, 'HH:mm:ss', true).format('HH:mm')}`,
										cd_dia_atendimento: data.dt_agenda,
										hr_inicio: data.hr_inicio,
										hr_fim: data.hr_fim,
										nr_vagas: data.nr_vagas,
										nr_vagas_total: data.nr_vagas_total,
										nr_encaixes: data.nr_encaixes,
										disabled: !data.nr_vagas
									})
								})
							}
						})
					} else {
						Agendamento.getDatasCadastro(params).then(response => {
							this.waitingDatas = false
							if (response.status == 200) {
								this.options.datas = []
								response.data.forEach(data => {
									this.options.datas.push(moment(data.dt_agenda)._d)
								})
							}
							if (this.isEorP) this.getHorarios()
						})
					}
					this.waitingDatas = false
				}

			},
			getEspecialistasByTag() {
				this.options.especialistasEstabelecimento = []
				this.model.cd_especialista = null

				let configs = { usarCidade: true }
				let params = {
					ie_atende_medclub_empresa: this.tipo_atendimento == 'E'
				}
				if (this.model.cd_area_atuacao) params.id_tag = this.model.cd_area_atuacao

				this.waitingEspecialistasEstabelecimento = true
				Especialista.getAllByParams(params, configs).then(response => {
					this.waitingEspecialistasEstabelecimento = false
					if(response.status == 200) {
						response.data.forEach(especialista => {
							this.options.especialistasEstabelecimento.push({
								value: especialista.id,
								text: especialista.nm_especialista,
								ds_observacao_agenda: especialista.ds_observacao,
								preco: especialista.id,
								preco_f: this.formatPrice(especialista.id)
							})
						})
					}
				})

			},
			getEspecialistasEstabelecimento (cd_area_atuacao) {
				this.options.especialistasEstabelecimento = []
				this.model.cd_especialista = null

				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento', 'cd_especialidade', 'cd_estabelecimento']
				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					cd_especialidade: this.model.cd_especialidade,
					cd_estabelecimento: this.model.cd_estabelecimento,
					ie_atende_medclub_empresa: this.tipo_atendimento == 'E'
				}

				if(cd_area_atuacao) {
					params.id_tags = this.model.cd_area_atuacao;
				}

				if (this.model.dt_agenda){
					params.dt_consulta = moment(this.model.dt_agenda).format('YYYY-MM-DD')
					let dateChecked = false
					this.options.datas.forEach(data => { if (moment(data).format('YYYY-MM-DD') == params.dt_consulta) dateChecked = true })
					if (!dateChecked) { this.model.dt_agenda = null; return 0 }
				}

				this.waitingEspecialistasEstabelecimento = true
				Agendamento.getEspecialistasCadastro(params).then(response => {
					this.waitingEspecialistasEstabelecimento = false
					if (response.status == 200) {
						this.options.especialistasEstabelecimento = []

						response.data.forEach(especialista => {
							this.options.especialistasEstabelecimento.push({
								value: especialista.cd_especialista,
								text: especialista.nm_especialista,
								ds_observacao_agenda: especialista.ds_observacao_agenda,
								preco: especialista.preco,
								preco_f: this.formatPrice(especialista.preco)
							})
						})
					}
					this.getHorarios()
				})
			},
			getEspecialistas () {
				this.options.especialistas = []
				this.model.cd_especialista = null

				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento', 'cd_especialidade', 'cd_estabelecimento']
				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					cd_especialidade: this.model.cd_especialidade,
					cd_estabelecimento: this.model.cd_estabelecimento,
					ie_atende_medclub_empresa: this.tipo_atendimento == 'E'
				}

				if ( this.selectedEspecialistaDropdown && this.model.cd_especialista_estabelecimento != null )
					params['cd_especialista'] = this.model.cd_especialista_estabelecimento

				if (this.model.dt_agenda){
					params.dt_consulta = moment(this.model.dt_agenda).format('YYYY-MM-DD')
					let dateChecked = false
					this.options.datas.forEach(data => { if (moment(data).format('YYYY-MM-DD') == params.dt_consulta) dateChecked = true })
					if (!dateChecked) { this.model.dt_agenda = null; return 0 }
				}

				this.waitingEspecialistas = true
				Agendamento.getEspecialistasCadastro(params).then(response => {
					this.waitingEspecialistas = false
					if (response.status == 200) {
						this.options.especialistas = []
						response.data.forEach(especialista => {
							this.options.especialistas.push({
								value: especialista.cd_especialista,
								text: especialista.nm_especialista,
								ds_observacao_agenda: especialista.ds_observacao_agenda,
								preco: especialista.preco,
								preco_f: this.formatPrice(especialista.preco),
								possui_integracao: especialista.possui_integracao,
								ie_permite_retorno: especialista.ie_permite_retorno
							})
						})
					}
					this.getHorarios()
				})
			},
			onEndScrollMedicoSolicitante(filterValue) {
				if (!this.medicoSolicitante.waiting && !this.medicoSolicitante.waitingNextPage && !this.medicoSolicitante.lastPage) {
					this.getMedicoSolicitanteOptions(filterValue, ++this.medicoSolicitante.page);
				}
			},
			getMedicoSolicitanteOptions(nm_especialista, page) {
				if (nm_especialista && nm_especialista.trim().length < 2) return;

				this.model.cd_especialista_solicitante = null;

				nm_especialista = nm_especialista || "";
				this.medicoSolicitante.page = page || 1;
				if (this.medicoSolicitante.page === 1) this.medicoSolicitante.lastPage = false;
				const per_page = 10;

				let params = {
					nm_especialista,
					page: this.medicoSolicitante.page,
					per_page
				};

				let waitingKey = (this.medicoSolicitante.page === 1) ? "waiting" : "waitingNextPage";
				this.medicoSolicitante[waitingKey] = true;

				Especialista.findAllClean(params).then(response => {
					this.medicoSolicitante[waitingKey] = false;
					if (response.status === 200) {
						let especialistas = response.data.map(e => {
							return {
								value: e.id,
								text: `${e.nm_especialista} - ${e.nm_conselho} ${e.nr_conselho}/${e.ds_uf_conselho}`
							};
						})
						if (this.medicoSolicitante.page === 1) this.options.medicosSolicitantes = especialistas;
						else this.options.medicosSolicitantes.push(...especialistas);
						if (response.data.length < per_page) this.medicoSolicitante.lastPage = true;
					}
				})
			},
			onDayClick (ev) {
				let dateChecked = false
				this.options.datas.forEach(data => { if (moment(data).format('YYYY-MM-DD') == ev.id) dateChecked = true })
				if (dateChecked) this.model.dt_agenda = moment(ev.id)._d
			},
			getHorarios () {
				this.options.horarios = []
				this.model.cd_horario_agendamento = null

				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento', 'cd_estabelecimento', 'dt_agenda']
				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! this.model.cd_especialista && ! this.model.cd_procedimento) requiredChecked = false
				if (! requiredChecked) return 0

				let params = {
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					cd_estabelecimento: this.model.cd_estabelecimento,
					dt_consulta: moment(this.model.dt_agenda).format('YYYY-MM-DD'),
					ie_atende_medclub_empresa: this.tipo_atendimento == 'E',
				}

				if (this.model.cd_especialista) {

					if(this.showMedicoExecutante) {
						params.cd_procedimento = this.model.cd_procedimento
					}
					params.cd_especialista = this.model.cd_especialista
					if (this.model.cd_especialidade) params.cd_especialidade = this.model.cd_especialidade
				} else {
					params.cd_procedimento = this.model.cd_procedimento
				}

				this.waitingHorarios = true
				Agendamento.getHorariosCadastro(params).then(response => {

					this.waitingHorarios = false
					if (response.status == 200) {
						this.options.horarios = {}
						response.data.forEach(horario => {
							if (this.options.horarios[horario.cd_agenda] == undefined)
								this.options.horarios[horario.cd_agenda] = {}

							if (this.options.horarios[horario.cd_agenda][horario.cd_dia_atendimento] == undefined)
								this.options.horarios[horario.cd_agenda][horario.cd_dia_atendimento] = []

							horario.ie_status = horario.status
							this.options.horarios[horario.cd_agenda][horario.cd_dia_atendimento].push({
								value: horario.id,
								text: moment(horario.hr_agenda, 'HH:mm:ss', true).format('HH:mm'),
								disabled: horario.ie_status != 'L',
								status: horario.status,
								cd_dia_atendimento: horario.cd_dia_atendimento,
								hr_inicio: horario.hr_inicio,
								hr_fim: horario.hr_fim,
								cd_agenda: horario.cd_agenda,
								nm_especialista: horario.nm_especialista,
								ds_motivo_bloqueio: horario.cd_dia_bloqueio__ds_motivo_bloqueio,
								ie_encaixado: horario.ie_encaixado
							})
						})

						for (let i in this.options.horarios) {
							for (let j in this.options.horarios[i]) {
								this.options.horarios[i][j].sort( (a, b) => {
									return Date.parse('01/01/2011 '+a.text+':00') > Date.parse('01/01/2011 '+b.text+':00')
								} )
							}
						}
					}
				})
			},
			openDialogCriarHorario(horarioBase) {
				if ([2683, 2943, 3202, 2385].includes(horarioBase.cd_agenda)) {
					this.$toast.error('Essa agenda não permite encaixe.', { duration: 3000 });
					return;
				}
				this.dialogCriarHorario_base = horarioBase;
				this.dialogCriarHorario_data = {};
				this.dialogCriarHorario = true;
			},
			criarHorario () {
				if (! moment(this.dialogCriarHorario_data.hr_agenda, 'HH:mm', true).isValid()) {
					this.$toast.error('Informe um horário válido', { duration: 3000 })
					return 0
				}
				this.$toast.success('Horário criado!', { duration: 3000 })
				this.dialogCriarHorario = false

				let novoHorario = {
					disabled: false,
					ie_encaixado: true,
					text: this.dialogCriarHorario_data.hr_agenda,
					value: this.dialogCriarHorario_data.hr_agenda,
					parent: this.dialogCriarHorario_base
				}

				this.options.horarios[this.dialogCriarHorario_base.cd_agenda][this.dialogCriarHorario_base.cd_dia_atendimento].push(novoHorario)
				this.model.cd_horario_agendamento = this.dialogCriarHorario_data.hr_agenda
1
				this.horarioSelecionado = novoHorario
			},
			todosHorariosIndisponiveis () {
				let check = true
				this.options.horarios.forEach(horario => { if (! horario.disabled) check = false })
				return check
			},
			incrementarQuantidade(horario, i){
				if (!['E', 'P', 'L'].includes(horario.info.ie_tipo_agenda)){
					return false;
				}

				let valorOriginal = horario.info.nr_quantidade;

				if( i === '+1' ){
					horario.info.nr_quantidade = horario.info.nr_quantidade + 1;
				} else if( i === '-1' ){
					horario.info.nr_quantidade = horario.info.nr_quantidade - 1;
				} else {
					horario.info.nr_quantidade = i
				}
				if( horario.info.nr_quantidade <=0 )
					horario.info.nr_quantidade = 1
				else {
					let _returnSuccess = (responseData) => {
						/*
						let agendamento = {
							id: responseData.id,
							paciente: this.$refs.buscarPaciente.model,
							info: Object.assign({}, this.model),
							dataSend
						}
						*/

						horario.info.nr_quantidade = responseData.nr_quantidade
						horario.info.preco_f = this.$root.formatPrice(responseData.nr_valor*responseData.nr_quantidade)
						horario.info.preco = responseData.nr_valor*responseData.nr_quantidade
						this.$toast.success('Horário Atualizado!', { duration: 3000 })
					}
					let dataSend = Object.assign({}, horario.info)
					dataSend = {
						cd_horario_agendamento:horario.id,
						cd_atendimento: this.cliente.atendimento.id,
						ie_reservar:'R',
						nr_quantidade: horario.info.nr_quantidade,
						cd_pessoa_fisica: horario.info.cd_paciente.id,
						cd_procedimento: horario.info.cd_procedimento_estabelecimento
					}
					this.waitingQuantidade = Object.assign({}, this.waitingQuantidade, {
						[horario.id] : false
					})
					this.waitingAgendar = true
					Agendamento.reservarHorario(dataSend).then(response => {

						this.waitingQuantidade = Object.assign({}, this.waitingQuantidade, {
							[horario.id] : false
						})
						this.waitingAgendar = false
						if (response.status == 200) _returnSuccess(response.data)
						else if (response.status == 400) {
							/*
							if (response.data.cd_horario_agendamento) {
								if (typeof response.data.cd_horario_agendamento == 'string')
									this.$toast.error(response.data.cd_horario_agendamento, { duration: 3000 })
								else response.data.cd_horario_agendamento.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
							}
							*/
							horario.info.nr_quantidade = valorOriginal;

							if (response.data.non_field_errors)
								response.data.non_field_errors.forEach(errorMsg => {
									this.$toast.error(errorMsg, { duration: 3000 })
								})
							else Object.values(response.data).forEach(errorMsg => {
								if (typeof errorMsg == 'object')
									this.$toast.error(errorMsg[0], { duration: 3000 })
								else this.$toast.error(errorMsg, { duration: 3000 })
							})
						}
					})
				}

			},
			copy(data) {
				copyToClipboard(`[#PEDIDO:${data}]`)
				this.$toast.success('Nº de Atendimento Copiado')
			},
			beforeMount() {
				[this.$refs.btnNovoAtendimento.classList, this.$refs.acoesReservados.classList].forEach(el=>{
					el.add(this.tipo_atendimento == 'E' ? 'p-justify-center' : 'p-justify-end')
				})
			},
			mudaTamanho(val) {
				this.$refs.agendamentos.classList.add(val.ie_status != 'A' ? 'p-col-6' : 'p-col-12');
				[this.$refs.cliente.classList, this.$refs.reservados.classList].forEach(el => {
					el.add(val.ie_status == 'A' ? 'p-col-6' : 'p-col-12')
				})
			},
			agendamentos_sum() {
				let sum = 0
				this.agendamentos.forEach(agendamento => sum += agendamento.info.preco)
				this.valorTotal = sum
				return this.formatPrice(sum)
			},
			orcamentos_sum() {
				let sum = 0
				this.orcamentos.forEach(orcamento => {
					if(orcamento.nr_valor_total_disponivel)
						sum += orcamento.nr_valor_total_disponivel
					else
						sum += orcamento.nr_valor
				})
				return this.formatPrice(sum)
			},
			imprimirOrcamento() {
				this.selecionados = []
				this.orcamentos.forEach( item => {
					if (item.procedimentos) {
						item.procedimentos.forEach(proc =>
							this.selecionados.push({
								...proc,
								nm_procedimento: proc.ds_observacao,
								nr_quantidade: proc.disponivel ? 1 : 0,
								cd_estabelecimento: item.cd_estabelecimento,
								ds_endereco: item.ds_endereco,
								nm_estabelecimento: item.nm_estabelecimento,
								estab_telefone: item.nr_telefone,
								nr_valor_total: proc.disponivel ? proc.nr_valor : 0,
								nr_valor_total_f: proc.disponivel ? formatPrice(proc.nr_valor) : formatPrice(0)
							})
						)
					} else this.selecionados.push({
						...item,
						nm_procedimento: item.ds_observacao,
						nr_quantidade: 1,
						estab_telefone: item.nr_telefone,
						nr_valor_total: item.nr_valor,
						nr_valor_total_f: formatPrice(item.nr_valor)
					})
				})
				this.dialogObsImprimir = true
			},
			validaEmpresaAssociada (data) {
				switch (true) {
					case this.tipo_atendimento != 'E':
						return false
					case !data:
						this.$toast.error('Selecione uma Empresa Associada', { duration: 3000 })
						return 0
					default:
						return true
				}
			},
			getAtendimento(id) {
				this.waitingAtendimento = true
				const atendimento = this.tipo_atendimento == 'E' ? MedEmpresa : Agendamento
				atendimento.getAtendimento(id).then(async res => {
					if (res.status == 200) {
						this.dialogAtendimentos_selected = res.data
						await this.onAtendimentoSelect()
					} else {
						this.$toast.error(res.data.detail)
					}
					this.waitingAtendimento = false
				}).catch(rej => {
					console.log(rej)
				})
			},
			redireciona(data){
				let rota = this.tipo_atendimento == 'E' ? '/med-empresa/atendimento/' : '/agendamento/salvar/'
				this.$router.push(rota + `${data.id}`)
				this.$router.go()
			},
			buscarPacote(){
				this.waitingPacotes = true;
				Pacote.vendaPacotes({ ie_tipo_pacote: this.model.ie_tipo_pacote, id: this.model.nm_busca_pacote }).then(response => {
					this.waitingPacotes = false;
					if(([200,201]).includes(response.status)){
						this.options.pacotes = 
							response.data.filter(item => item.valor.length > 0 && !this.agendamentos.filter(i => i.cd_pacote).find(i => i.id == item.id))
					}
				})
			},
			getPacotes (ds_pacote, page) {
				if (ds_pacote && ds_pacote.trim().length < 2) return

				ds_pacote = ds_pacote || ""
				this.pagePacote = page || 1
				if (this.pagePacote == 1) this.lastPagePacote = false;
				let per_page = 10

				let requiredChecked = true
				let fields = ['ie_tipo_agenda', 'ie_tipo_atendimento']
				fields.forEach(field => { if (! this.model[field]) requiredChecked = false })
				if (! requiredChecked) return 0

				let params = {
					page: this.pagePacote,
					per_page: per_page,
					paginacao: true,
					ds_pacote: ds_pacote,
					ie_tipo_agenda: this.model.ie_tipo_agenda,
					ie_telemedicina: this.model.ie_tipo_atendimento == 2,
					ie_atende_medclub_empresa: this.tipo_atendimento == 'E',
					ie_tipo_pacote: this.model.ie_tipo_pacote,
					ie_status: true,
					ie_vigente: true,
				}
				let configs = { ignorarEstabelecimento: true, usarCidade: true }

				let waitingKey = (this.pagePacote === 1) ? "waitingPacotes" : "waitingNextPagePacotes";
				this[waitingKey] = true;

				Pacote.findAll(params, configs).then(response => {
					this[waitingKey] = false
					if (response.status == 200) {
						let procedimentos = response.data.results.map(pacote => {
							return {
								value: pacote.id,
								text: `${ pacote.ds_pacote }`
							}
						})
						if (this.pagePacote === 1) {
							this.options.tiposPacotes = []
							this.options.tiposPacotes.push({ value: null, text:'- Selecione -' })
							this.options.tiposPacotes.push(...procedimentos)
						} else {
							this.options.tiposPacotes.push(...procedimentos)
						}
						if (response.data.results.length < per_page) this.lastPagePacote = true;
					}
				})
			},
			unifyPacotes(pacotes){
				return [...new Set(pacotes.map(item => (item.nm_fantasia)))];
			},
			limparPacotes(){
				this.options.pacotes = [];
				this.selectedPacote = null;
				this.model.nm_busca_pacote = null;
			},
			async excluirPacotes(id) {
				const dataSend = {
					id: id,
					cd_atendimento: this.idAtendimento != 0? this.idAtendimento : this.cliente.atendimento.id,
					cd_pessoa_fisica: this.$refs.buscarPaciente.cd_pessoa_fisica,
					ie_reservar: "L",
					cd_procedimentos_estabelecimentos: this.agendamentos.find(item => item.id == id).cd_procedimentos.map(i => ({
						id: i.info.cd_procedimento_estabelecimento,
						horario_id: i.id
					}))
				}
				try{
					this.waitingRemoverPacote = id
					const response = await Agendamento.removerPacote(dataSend)
					this.waitingRemoverPacote = 0
					if(!([200,201]).includes(response.status)) 
						throw new Error(response.data?.detail)
					this.agendamentos = this.agendamentos.filter(item => item.id != id)
					this.$toast.success("Pacote removido com sucesso")
				}catch(e){
					this.$toast.error("Erro na remoçao de pacote")
				}
			},
			async agendaPacotes(dados = null){
				let temAgenda = false;
				if(this.selectedPacote.ie_bloqueado) {
					this.dialogPacoteBloqueado = true
					return
				}
				this.selectedPacote.itens_pacote.forEach(item => {
					if(item.ie_hora_marcada) temAgenda = true
				})
				if(temAgenda && dados == null){
					this.dialogPacotes = true;
					return
				}
				let dataSend = dados || {
					cd_atendimento: this.idAtendimento != 0? this.idAtendimento : this.cliente.atendimento.id,
					cd_pessoa_fisica: this.$refs.buscarPaciente.cd_pessoa_fisica,
					ie_reservar: "R",
				}
				try{
					this.waitingCadastroAgenda = true;
					let response = await Agendamento.reservarPacote(this.selectedPacote.id, dataSend)
					this.waitingCadastroAgenda = false;
					if(!([200,201,204]).includes(response.status)){
						throw new Error(response.data.detail)
					}
					let ags = []
					let tipo_agenda = { 'C': 'Consulta', 'E': 'Exame', 'L': 'Laboratorial', 'CH': 'Procedimento (check-up)'}
					response.data.forEach(ag => {
						ag.ie_tipo_agenda_f = tipo_agenda[ag.cd_checkup ? 'CH' :  ag.ie_tipo_agenda]
						ag.preco = parseFloat(ag.nr_valor_total)
						ag.preco_f = ag.nr_valor_total_f ?? this.formatPrice(ag.preco)

						ags.push({
							id: ag.id,
							paciente: this.$refs.buscarPaciente.model,
							info: {...ag, nm_paciente: this.$refs.buscarPaciente.model.nm_pessoa_fisica},
							dataSend: ag
						})
					})
					this.agendamentos.push({
						...ags[0].info.cd_pacote,
						ie_pacote: true,
						cd_procedimentos: ags,
						info: {
							cd_paciente: ags[0].info.cd_paciente,
							cd_atendimento: ags[0].info.cd_atendimento,
							cd_procedimentos: ags,
							nr_valor: ags[0].info.nr_valor_total,
							nm_paciente: ags[0].paciente.nm_pessoa_fisica,
							nr_cpf: ags[0].info.nr_cpf,
							ie_tipo_agenda: ags[0].info.ie_tipo_agenda,
							ie_status: ags[0].info.ie_status,
							preco: ags.reduce((acc, item) => {
								return acc + item.info.nr_valor_total
							}, 0)
						}
					})
					this.$toast.success("Pacote agendado")
					this.dialogPacotes = false
					this.limparPacotes()
					// this.$router.go(0)
				}catch(e){
					console.log(e)
					this.$toast.error("Erro ao cadastrar agenda")
				}
			}
		}
	}
</script>
